var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"modal-attendance","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"scroll-this"},[_c('div',{staticClass:"position-relative mx-4"},[(_vm.origin === 'history' && _vm.attendance?.logs?.length)?_c('div',[_c('span',{staticClass:"position-absolute ps-b-0 ps-t-0 ps-l-0 border-left bg-dark"}),_vm._l((_vm.attendance.logs),function(log,index){return _c('div',{key:`${log.author}${index}`,staticClass:"py-4 pl-5 position-relative"},[_c('span',{staticClass:"dot-log position-absolute p-2 rounded-circle mt-2",class:{
                                        'bg-primary': index === 0,
                                        'bg-secondary': index > 0
                                    }}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('span',{staticClass:"text-dark font-14"},[_c('b',[_vm._v(_vm._s(log.status))])])]),_c('div',{staticClass:"col-9"},[_c('span',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(log.date_time)+" ")])]),(log.logType === 'user')?[(log.description)?[_vm._m(1,true),_c('div',{staticClass:"col-9"},[_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(log.description))])])]:_vm._e(),_vm._m(2,true),_c('div',{staticClass:"col-9"},[_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(log.author))])]),_vm._m(3,true),_c('div',{staticClass:"col-9"},[_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(log.proceeding ?? 'Sem procedimento vinculado'))])])]:[_vm._m(4,true),_vm._m(5,true)]],2)])})],2):_vm._e(),(_vm.attendance_id)?_c('scheduling-card',{ref:"scheduling_card",attrs:{"attendance_id":_vm.attendance_id},on:{"update-attendance":function($event){_vm.attendance = $event},"update-payment-info":_vm.HandleUpdatePaymentInfo}}):_vm._e()],1)]),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"d-flex p-4",class:{
                        'justify-content-between': _vm.is_scheduling_origin,
                        'justify-content-end': !_vm.is_scheduling_origin,
                    }},[(_vm.is_scheduling_origin)?_c('button',{staticClass:"btn btn-light p-3 px-4",on:{"click":_vm.ShowPaymentMethods}},[_vm._v(" pagamento ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary p-3 px-4",on:{"click":_vm.PrintAttendance}},[_vm._v(" imprimir "),_c('i',{staticClass:"far fa-print ml-3"})])])])])]),(_vm.is_scheduling_origin)?_c('PaymentMethodsModal',{attrs:{"total":_vm.payment_info.total,"payment_methods_props":_vm.payment_info},on:{"payment-methods-added":_vm.UpdatePaymentInfo}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header px-5 py-4 mb-4"},[_c('button',{staticClass:"position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3",attrs:{"type":"button","data-dismiss":"modal"}},[_c('i',{staticClass:"far fa-times mx-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v("Descrição")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v("Autor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v("Procedimento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v("Autor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-9"},[_c('span',{staticClass:"text-dark"},[_vm._v("SISTEMA")])])
}]

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ProceedingAndPartnership.vue?vue&type=template&id=8a39e686&scoped=true"
import script from "./ProceedingAndPartnership.vue?vue&type=script&lang=js"
export * from "./ProceedingAndPartnership.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a39e686",
  null
  
)

export default component.exports
<template>
	<div class="logout-container">
		<div class="w-100 h-100 d-flex justify-content-center align-items-center">
			<h2>Saindo...</h2>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'Logout',
	computed: {
		...mapGetters('auth', [
			'tab_id'
		]),
	},
	methods: {
		...mapActions('auth', [
			'Logout'
		])
	},
	mounted () {
		const tab_id = this.$route.query?.tab_id

		if(tab_id) {
			window.location.href = `${process.env.VUE_APP_LANDING_PAGE_URL}/login`
		} else {
			this.Logout()
		}
	}
}
</script>

<style lang="css" scoped>
.logout-container {
	width: 100vw;
	height: 89vh;
}
</style>
<template>
    <div class="col-12 px-0">
		<h5>Histórico de acessos</h5>
		<div class="col-12 px-0 d-flex justify-content-center">
			<template v-if="loading_accesses">
				<div class="spinner-border text-theme" role="status"></div>
			</template>
			<template v-else>
				<DataTables
					class="col-12 px-0"
					:max_height="'380px'"
					:fixedHeader="true"
					v-model="history"
					:headers="headers"
					:scrollUpdate="true"
					:tr-button="false"
					:prop_sortedBy="sortedBy"
					@load-table="UpdateTable"
				>
					<template v-slot:body="{ item }">
						<td class="align-middle">
							{{ item.date_time }}
						</td>
						<td class="align-middle">
							{{ item.ip_address }}
						</td>
						<td class="align-middle">
							{{ item.type }}
						</td>
						<td class="align-middle">
							{{ item.browser }}
						</td>
						<td class="align-middle">
							{{ item.os }}
						</td>
						<td class="align-middle">
							{{ item.location }}
						</td>
					</template>
				</DataTables>
			</template>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
	name: 'AccessHistory',
	data() {
		return {
			history: [],
			headers: [
				{ text: 'Data/Hora', value: 'date_time', sortable: true },
				{ text: 'IP', value: 'ip_address' },
				{ text: 'Tipo', value: 'type', sortable: true },
				{ text: 'Navegador', value: 'browser' },
				{ text: 'Sistema Operacional', value: 'os' },
				{ text: 'Localização', value: 'location' }
			],
            sortedBy:		{
                column: 		'date_time',
                direction:		'DESC',
                icon:			'fa-chevron-down'
            },
			limit: 50,
			offset: 0,
			total_history: 0,
			loading_accesses: false,
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		GetAccessHistory(clear = true) {
			this.offset = clear ? 0 : this.offset

			this.attendances = clear ? [] : this.attendances

			this.StartLoading()

			return window.api.call('post', '/get-access-history', {
				limit: this.limit,
				offset: this.offset
			})
				.then(({data}) => {
					if(data.response) {
						this.total_history = data.total_history

						const parsed_history = data.logs.map(log => {
							let type = 'PC'
	
							if(log.access_info.is_mobile) {
								type = 'Celular'
							} else if(log.access_info.is_tablet) {
								type = 'Tablet'
							}

							let location = ''

							const { city, region_code } = log.location;
    						location = [city, region_code].join(' - ');
	
							return {
								date_time: moment(log.created_at).format('DD/MM/YYYY HH:mm:ss'),
								timestamp: moment(log.created_at).unix(),
								ip_address: log.access_info.ip_address,
								type,
								browser: log.access_info.browser,
								os: log.access_info.os,
								location
							}
						}) 
						
						this.history = _.orderBy([ ...this.history, ...parsed_history ], 'created_at', 'desc')
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		async UpdateTable(param) {
			this.offset += this.limit
			if(this.history.length >= this.total_history) {
				Swal.fire({
					text: 				"Não há mais registros de acesso.",
					toast: 				true,
					position: 			'top',
					title:				' ',
					icon:				'info',
					timer:				4000,
					timerProgressBar:	true
				})

				return;
			}

			await this.GetAccessHistory(false)
		},
	},
	mounted() {
		this.GetAccessHistory()
	}
}
</script>

<style>

</style>
<template>
    <div class="modal fade" id="modal-attendance" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header px-5 py-4 mb-4">
                    <button type="button" class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" data-dismiss="modal">
                        <i class="far fa-times mx-1"></i>
                    </button>
                </div>
                
                <hr class="m-0">
                <div class="modal-body">
                    <div class="scroll-this">
                        <div class="position-relative mx-4">
                            <div v-if="origin === 'history' && attendance?.logs?.length">
                                <span class="position-absolute ps-b-0 ps-t-0 ps-l-0 border-left bg-dark"></span>
                                <div class="py-4 pl-5 position-relative"
                                    v-for="(log, index) in attendance.logs"
                                    :key="`${log.author}${index}`"
                                >
                                    <span 
                                        class="dot-log position-absolute p-2 rounded-circle mt-2"
                                        :class="{
                                            'bg-primary': index === 0,
                                            'bg-secondary': index > 0
                                        }"
                                    ></span>
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="text-dark font-14">
                                                <b>{{ log.status }}</b>
                                            </span>
                                        </div> 
                                        <div class="col-9">
                                            <span class="text-dark">
                                                {{ log.date_time }}
                                            </span>
                                        </div>
                                        <template v-if="log.logType === 'user'">
                                            <template v-if="log.description">
                                                <div class="col-3">
                                                    <span class="text-muted font-14">Descrição</span>
                                                </div>
                                                <div class="col-9">
                                                    <span class="text-dark">{{ log.description }}</span>
                                                </div>
                                            </template>
                                            <div class="col-3">
                                                <span class="text-muted font-14">Autor</span>
                                            </div>
                                            <div class="col-9">
                                                <span class="text-dark">{{ log.author }}</span>
                                            </div>
                                            <div class="col-3">
                                                <span class="text-muted font-14">Procedimento</span>
                                            </div>
                                            <div class="col-9">
                                                <span class="text-dark">{{ log.proceeding ?? 'Sem procedimento vinculado' }}</span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-3">
                                                <span class="text-muted font-14">Autor</span>
                                            </div>
                                            <div class="col-9">
                                                <span class="text-dark">SISTEMA</span>
                                            </div>
                                        </template>
                                    </div>					
                                </div>
                            </div>
                            <scheduling-card v-if="attendance_id"
                                ref="scheduling_card"
                                :attendance_id="attendance_id"
                                @update-attendance="attendance = $event"
                                @update-payment-info="HandleUpdatePaymentInfo"
                            />
                        </div>
                    </div>
                    <hr class="m-0">
                    <div class="d-flex p-4"
                        :class="{
                            'justify-content-between': is_scheduling_origin,
                            'justify-content-end': !is_scheduling_origin,
                        }">
                        <button
                            v-if="is_scheduling_origin"
                            class="btn btn-light p-3 px-4"
                            @click="ShowPaymentMethods"
                        >
                            pagamento
                        </button>
                        <button 
                            class="btn btn-primary p-3 px-4"
                            @click="PrintAttendance"
                        >
                            imprimir
                            <i class="far fa-print ml-3"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <PaymentMethodsModal
            v-if="is_scheduling_origin"
            :total="payment_info.total"
            :payment_methods_props="payment_info"
            @payment-methods-added="UpdatePaymentInfo"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const moment 	= require('moment')

import SchedulingCard from '../search/SchedulingCard'
import PaymentMethodsModal from '../schedule/components/PaymentMethodsModal'
import Swal from 'sweetalert2'

export default {
    name: "AttendanceModal",
    components: {
        SchedulingCard,
        PaymentMethodsModal
    },
    props: {
        attendance_id: 	{
            type:           Number,
            default:        null,
        },
        origin:         {
            type:           String,
            default:        ''
        }
    },
    data() {
        return {
            ORIGINS: {
                SCHEDULING_PRE_APPOINTMENT: 2,
                SCHEDULING_URGENT_APPOINTMENT: 3,
                FIT: 4,
            },
            payment_info: {
                total: 0
            },
            scheduling_id: null,
            attendance: null,
        }
    },
    computed: {
        ...mapGetters('auth', [
            'requester_selected_id'
        ]),
        is_scheduling_origin() {
            return this.attendance && [
                this.ORIGINS.SCHEDULING_PRE_APPOINTMENT,
                this.ORIGINS.SCHEDULING_URGENT_APPOINTMENT,
                this.ORIGINS.FIT,
            ].indexOf(this.attendance.origin) > -1
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        CloseModal() {
            window.jQuery('#modal-attendance').modal('hide')
        },
        GetAttendance() {
            this.StartLoading()

            return window.api.call('post', '/get-attendances', {
                account_id: this.requester_selected_id,
                attendance_id: this.attendance_id
            })
                .then(({data}) => {
                    if(data.response) {
                        if(data.scheduling) {
                            this.attendance = data.scheduling
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro!',
                            text: 'Houve um erro ao buscar os dados do atendimento, contate o suporte.'
                        })
                    }
                })
                .finally(() => {
                    this.FinishLoading()
                })
        },
        PrintAttendance() {
            const vm = this
            
            const fn = (event) => {
                if(event.data.print) {
                    window.removeEventListener('message', fn)

                    win.close()
                }
            }

            let query = `attendance_id=${vm.attendance.id}`

            if(vm.origin === 'search') {
                query = `journey_id=${vm.attendance.journey_id}`
            }

            const win     	= window.open(`/pesquisa/imprimir?${query}`, '_blank')
            win.mainWindow  = window
            window.addEventListener('message', fn)
        },
        FormatDate(value, fmt)
        {
            const m   = moment(value, 'YYYY-MM-DD HH:mm:ss')

            return m.format(fmt)
        },
        ShowPaymentMethods() {
            window.jQuery('#payment-methods-modal').modal('show')
        },
        HandleUpdatePaymentInfo(payment_info) {
            this.payment_info = payment_info
        },
        UpdatePaymentInfo(payment_info) {
            this.StartLoading()

            return window.api.call('post', '/update-payment-info', {
                payment_info,
                journey_id: this.attendance.journey_id,
                scheduling_id: this.attendance.scheduling_id
            })
            .then(({data}) => {
                if(data.response) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Informações de pagamento salvas com sucesso.',
                        toast: true,
                        timer: 3000,
                        position: 'top',
                        timerProgressBar: true
                    })
                } else {
                    Swal.fire({
                       icon: 'error',
                        title: 'Erro!',
                        text: 'Houve um erro ao salvar as informações de pagamento, contate o suporte.' 
                    })
                }
            })
            .finally(() => {
                this.FinishLoading()
            })
		}
    }
}
</script>

<style lang="css" scoped>
    .modal-lg {
        max-width: 850px;
    }

    .dot-log {
        left: -5px;
    }
</style>
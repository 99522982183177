<template>
	<div class="modal fade" id="indicator_modal" tabindex="1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body p-4">
					<div v-show="form">
						<div class="form-row align-items-center mb-3">
							<div class="col-md-auto">
								<button
									class="btn btn-block btn-light"
									@click="form = false"
								>
									voltar
								</button>
							</div>
						</div>
						<IndicatorForm
							ref="indicator"
							:base_name="search"
							:indicator_base="indicator"
							@saved="IndicatorSaved"
							@deleted="IndicatorDeleted"
						/>
					</div>
					<div v-if="!form">
						<div class="d-flex align-items-center mb-3">
							<div class="col px-0">
								<div class="form-group position-relative m-md-0">
									<input
										type="text"
										id="indicator-search"
										ref="indicator_search"
										class="form-control pl-5"
										placeholder="Pesquisar por nome, telefone ou documento"
										v-model="search"
										autofocus
									>
									<i class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"></i>
								</div>
							</div>
							<div v-if="filteredIndicators.length === 0 && search" class="col-md-auto">
								<button
									class="btn btn-block btn-primary"
									@click="OpenModal()"
								>
									Cadastrar
								</button>
							</div>
						</div>
						<div class="card border-no bg-light rounded indicators-box">
							<div v-if="filteredIndicators.length > 0">
								<div v-for="indicator in filteredIndicators"
									:key="indicator.id"
								>
									<div class="select-card p-3 rounded">
										<label class="bg-white"  @click.prevent="SelectIndicator(indicator)">
											<div class="d-flex align-items-center">
												<div>
													{{ indicator.name }}
												</div>
											</div>
											<div v-if="indicator.crm">
												<small>CRM: {{ indicator.crm }}</small>
											</div>
											<div v-if="indicator.cpf">
												<small>CPF: {{ indicator.cpf }}</small>
											</div>
											<div v-if="indicator.phone">
												<small>Telefone: {{ indicator.phone }}</small>
											</div>
											<i class="fa fa-pencil mr-4" aria-hidden="true" @click.stop="EditIndicator(indicator)"></i>
										</label>
									</div>
								</div>
							</div>
							<div v-else class="card m-5 p-3 rounded">
								<span>
									Nenhum solicitante encontrado para "{{ search }}"
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const _ = require('lodash')
import Helpers from '../../../helpers'
const helpers 		= new Helpers()
import { mapGetters } from 'vuex'
import IndicatorForm from '../../shared/IndicatorForm.vue'

export default {
	name: 'IndicatorModal',
	data() {
		return {
			search: '',
			indicators: [],
			form: false,
			indicator: null
		}
	},
	components: {
		IndicatorForm
	},
	computed: {
		...mapGetters('auth', [
			'requester_selected_id'
		]),
		filteredIndicators() {
			if(!this.search) return this.indicators;

			const indicators	= this.indicators.filter(indicator => {
				let allowed 	= false
				let search 		= helpers.RemoveAcento(this.search).toLowerCase()

				if(indicator.name) {
					allowed 		= helpers.RemoveAcento(indicator.name).toLowerCase().includes(search)
				}

				if(!allowed && indicator.cpf) {
					allowed 		= helpers.RemoveAcento(helpers.SanitizedDocument(indicator.cpf)).toLowerCase().includes(helpers.SanitizedDocument(search))
				}

				if(!allowed && indicator.crm) {
					allowed 		= helpers.RemoveAcento(helpers.SanitizedDocument(indicator.crm)).toLowerCase().includes(helpers.SanitizedDocument(search))
				}

				if(!allowed && indicator.address) {
					allowed 		= helpers.RemoveAcento(indicator.address).toLowerCase().includes(search)
				}

				if(!allowed && indicator.observation) {
					allowed 		= helpers.RemoveAcento(indicator.observation).toLowerCase().includes(search)
				}

				if(!allowed && indicator.phone) {
					let telefone

	                try {
	                    telefone = JSON.parse(indicator.phone.replace(/\\/g,''))
	                } catch(err) {
	                    telefone = []
	                }

					if(Array.isArray(telefone) && telefone.length > 0) {
						telefone.forEach( tel => {
							if(tel && !allowed && tel.replace(/\D/g, '').indexOf(search) > -1) {
								allowed 	= true

								return;
							}
						})
					}
				}

				return allowed
			})

			return indicators
		}
	},
	methods: {
		OpenModal(){
			this.form = true
		
			this.$refs.indicator.ClearForm(true)
		},
		async GetIndicatorIndicators() {
			window.api.call('post', '/get-indicators', { 
				account_id: this.requester_selected_id 
			})
				.then(({data}) => {
					if(data.response) {
						this.indicators = _.sortBy(data.indicators, 'name');
					}
				})
		},
		SelectIndicator(indicator) {
			this.$emit('indicator', indicator)
		},
		EditIndicator(indicator) {
			this.indicator = { ...indicator }

			this.form = true
		},
		IndicatorSaved(indicator) {
			this.GetIndicatorIndicators()

			this.search = indicator.cpf

			this.form = false
		},
		IndicatorDeleted() {
			this.GetIndicatorIndicators()

			this.form = false
		}
	},
	beforeMount() {
		this.GetIndicatorIndicators()
	}
}
</script>

<style>
.indicators-box {
	max-height: 650px;
    overflow: auto;
}
</style>
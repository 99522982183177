<template>
	<div class="">
		<div class="form-row">
			<div class="form-group col-12">
			    <label for="indicator_name">Nome</label>
			    <input 
					type="text"
					id="indicator_name"
					class="form-control"
					:class="{ 'is-invalid': $v.indicator.name.$error }"
					placeholder="Nome do médico"
					v-model="$v.indicator.name.$model"
					autofocus
				>
				<div class="invalid-feedback">
					Obrigatório
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-7">
			    <label for="indicator_cpf">CPF</label>
			    <input 
					type="text"
					id="indicator_cpf"
					class="form-control"
					:class="{ 'is-invalid': $v.indicator.cpf.$error }"
					placeholder="000.000.000-00"
					v-mask="['###.###.###-##']"
					v-model="$v.indicator.cpf.$model"
				>
				<div class="invalid-feedback" v-if="!$v.indicator.cpf.CPFValidation">
					CPF inválido!
				</div>
			</div>
			<div class="form-group col-5">
			    <label for="indicator_crm">CRM</label>
			    <input 
					type="text"
					id="indicator_crm"
					class="form-control"
					maxlength="15"
					placeholder="00000000XX"
					v-model="indicator.crm"
				>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-6">
			    <label for="indicator_specialty">Especialidade</label>
			    <input 
					type="text"
					id="indicator_specialty"
					class="form-control"
					placeholder="Diagnostico por imagem"
					v-model="indicator.specialty"
				>
			</div>
			<div class="form-group col-6">
			    <label for="indicator_phone">Telefone</label>
			    <input 
					type="text"
					id="indicator_phone"
					class="form-control"
					placeholder="(00) 00000-0000"
					v-mask="['(##) #####-####', '(##) ####-####']"
					v-model="indicator.phone"
				>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-9">
			    <label for="indicator_address">Endereço</label>
			    <input 
					type="text"
					id="indicator_address"
					class="form-control"
					placeholder="R. São Paulo, 2001"
					v-model="indicator.address"
				>
			</div>
			<div class="form-group col-3">
			    <label for="indicator_zipcode">CEP</label>
			    <input 
					type="text"
					id="indicator_zipcode"
					class="form-control"
					placeholder="0000-000"
					v-model="indicator.zipcode"
				>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-8">
			    <label for="indicator_district">Bairro</label>
			    <input 
					type="text"
					id="indicator_district"
					class="form-control"
					placeholder="Centro"
					v-model="indicator.district"
				>
			</div>
			<div class="form-group col-4">
			    <label for="indicator_complement">Complemento</label>
			    <input 
					type="text"
					id="indicator_complement"
					class="form-control"
					v-model="indicator.complement"
				>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-12">
			    <label for="indicator_observation">Observação</label>
			    <textarea 
					id="indicator_observation"
					class="form-control"
					v-model="indicator.observation"
					cols="30"
					rows="2"
				></textarea>
			</div>
		</div>
		<div class="d-flex flex-wrap justify-content-between">
			<button type="button" class="btn btn-light-danger" @click="DeleteButton">
				excluir
				<i aria-hidden="true"></i>
			</button>
			<button type="button" class="btn btn-success" @click="Save">
				salvar
				<i aria-hidden="true"></i>
			</button>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

const CPFValidation = (value) => {
	if(!value || value.length == 0) return true
	
	return window.helpers.ValidaCPF(value)
}

export default {
	name: 'IndicatorForm',
	data() {
		return {
			indicator: {
				name: '',
				cpf: '',
				crm: '',
				specialty: '',
				address: '',
				district: '',
				complement: '',
				zipcode: '',
				phone: '',
				observation: ''
			}
		}
	},
	props: {
        indicator_base: {
            type: [Object],
            required: false,
            default: () => {}
        },
		base_name: {
			type: String,
			default: ''
		}
    },
	validations() {
		return {
			indicator: {
				name: {
					required,
				},
				cpf: {
					CPFValidation
				}
			}
		}
	},
	computed: {
		...mapGetters('auth', [
			'requester_selected_id'
		]),
	},
	watch: {
		base_name: {
			handler(val) {
				this.indicator.name = val ?? ''
			},
			immediate: true
		},
		indicator_base: {
			handler(val) {
				if(val) {
					this.indicator = val
				}
			},
			immediate: true
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		ClearForm(keep_base_name = false) {
			this.indicator = {
				name: keep_base_name ? this.base_name : '',
				cpf: '',
				crm: '',
				specialty: '',
				address: '',
				district: '',
				complement: '',
				zipcode: '',
				phone: '',
				observation: ''
			}
		},
		Save() {
			this.$v.indicator.$touch()

			if(this.$v.indicator.$invalid) return;

			this.StartLoading()

			window.api.call('post', '/new-indicator', { ...this.indicator, account_id: this.requester_selected_id })
				.then(({data}) => {
					if(data.response) {
						this.$emit('saved', data.indicator)

						Swal.fire({
							icon: 'success',
							toast: true,
							position: 'top',
							timer: 5000,
							html: 'Solicitante salvo'
						})
					} else {
						Swal.fire({
							title: 	'Eita!',
							text: 	'Houve um erro ao salvar, contate o suporte.',
							icon: 	'error'
						})
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		DeleteButton() {
			Swal.fire({
				title: 'Tem certeza?',
				text: 'Esta ação não pode ser desfeita!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sim, deletar',
				cancelButtonText: 'Cancelar',
			})
			.then((result) => {
				if(result.isConfirmed) {
					this.Delete(this.indicator)
				}
			})
		},
		async Delete(indicator) {
			this.StartLoading()

			await window.api.call('post', '/delete-indicator', { 
					...indicator,
				})
				.then(({ data }) => {
					if (data.response) {
					this.$emit('deleted', this.indicator.id)

					Swal.fire({
						title: 'Excluído!',
						text: 'O solicitante foi excluído com sucesso.',
						icon: 'success'
					})


					} else {
						Swal.fire({
							title: 'Erro!',
							text: 'Houve um problema ao tentar deletar o solicitante.',
							icon: 'error'
						})
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		}
	}
}
</script>

<style>

</style>
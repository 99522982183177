<template>
	<div :class="{'container-large': !carousel}">
		<ActiveSessionsModal
			v-if="check_sessions"
			ref="active_sessions"
		/>
		<div class="row pb-5 justify-content-start">
			<Menu :carousel="carousel"/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
	import Menu from './Menu'
	import ActiveSessionsModal from './ActiveSessionsModal'
	export default {
		name: 'Home',
		components: {
			Menu,
			ActiveSessionsModal
		},
		props: {
			check_sessions: {
				type: Boolean,
				default: false
			},
			carousel: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			]),
			is_confirmed() {
				const is_confirmed = this.requesters.find(r => r.account_requester_id == this.requester_selected_id)?.confirmed ?? false

				if(this.requester_selected_id && !is_confirmed) {
					this.$router.push({ name: 'Organizations' })
				}
				
				return is_confirmed
			}
		},
		watch: {
			is_confirmed: {
				handler(val) {
					if(this.requester_selected_id && !val) {
						this.$router.push({ name: 'Organizations' })
					}
				}
			}
		},
		methods: {
			async HandleSessionKey() {
				if(!this.$route.query?.session_data) return
				
				const session_data = JSON.parse(this.$route.query.session_data)

				if(session_data) {
					window.session_data = session_data

					this.$router.replace({ query: null })
				}
			}
		},
		mounted() {
			this.HandleSessionKey()
		}
	}
</script>

<style>

</style>
import Vue from 'vue'

window._ 			= require('lodash')

window.axios        = require('axios');

window.axios.defaults.headers.common['X-Requested-With']   = 'XMLHttpRequest';

window.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/management`

window.logo_url		= require('./assets/img/logo_original.png')

const authToken = localStorage.getItem('token');

if(authToken) {
    window.axios.defaults.headers.common['Authorization']     = 'Bearer ' + authToken;
}

const session_key = sessionStorage.getItem('session_key');

if(session_key) {
	window.axios.defaults.headers.common['X-Session-Key']     = session_key;
}

try {
    window.Popper 	= require('popper.js');
    window.$ 		= window.jQuery = require('jquery');

    require('bootstrap');

} catch (e) {

	console.log('error: ' + e.toString())
}


window.Event   = new Vue

import VueDebounce from 'vue-debounce'
Vue.use(VueDebounce, {
    listenTo: 'input'
})

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//HELPERS JS
import Helpers from './helpers'
window.helpers 		= new Helpers()

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

import money from 'v-money'
Vue.use(money, {
	precision: 	2,
	prefix: 	'R$ ',
	decimal: 	',',
	thousands: 	'.'
})

import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

import { StripeElementCard } from '@vue-stripe/vue-stripe';
Vue.use(StripeElementCard);

import './assets/sass/styles.scss'

require('./global-components')
require('./global-variables')
require('./directives')
import { v4 as uuidv4 } from 'uuid';
import Vuex from '../../vuex';

let requesting_session = false
let already_has_session = false

const time_icon = 'https://arquivo.instantmedical.com.br/api/cdn/v1/time_icon.png-72522'

class Session {
	session_key = null
	tab_id = null
	token = null
	account = null
	organization_id = null
	is_redirected = false
	is_duplicated = false

	constructor () {
		this.session_key = sessionStorage.getItem('session_key') ?? localStorage.getItem('session_key') ?? null
		this.tab_id = sessionStorage.getItem('tab_id') ?? localStorage.getItem('tab_id') ?? null
		this.token = sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? null
		this.account = this.HandleAccount()
		this.organization_id = new Vuex().getters['auth/requester_selected_id']
		this.is_duplicated = sessionStorage.getItem('is_duplicated') === 'true' ? true : false
	}

	async Init() {
		await this.GetSessionAndTabId();
		await this.InactiveHandlers();
	}

	async GetSessionAndTabId() {
		if(window.session_data) {
			this.is_redirected = true

			this.session_key = window.session_data.session_key
			this.tab_id = window.session_data.tab_id
			this.token = window.session_data.token
			this.is_duplicated = window.session_data.is_duplicated

			const tabsOpened = JSON.parse(localStorage.getItem('tabsOpened')) || []

			if(tabsOpened.indexOf(this.tab_id) === -1) {
				tabsOpened.push(this.tab_id)
				localStorage.setItem('tabsOpened', JSON.stringify(tabsOpened))

				this.TabListener(this.tab_id);
			}

			new Vuex().dispatch('auth/SetSessionKey', this.session_key)
			new Vuex().dispatch('auth/SetTabId', this.tab_id)

			sessionStorage.setItem('token', this.token)
			sessionStorage.setItem('is_duplicated', this.is_duplicated)

			already_has_session = true

			delete window.session_data
		} else {
			this.is_redirected = false
		}
	}

	async HandleTabId() {
		let tab_id = this.tab_id;
	
		let is_new_session = false;
	
		const tabsOpened = JSON.parse(localStorage.getItem('tabsOpened')) || [];

		const is_in_list = tabsOpened.indexOf(tab_id) > -1;
	
		if(!tab_id || is_in_list) {
			tab_id = uuidv4();
			is_new_session = true;
		}
		
		this.tab_id = tab_id;
		new Vuex().dispatch('auth/SetTabId', this.tab_id)
	
		tabsOpened.push(tab_id);
		localStorage.setItem('tabsOpened', JSON.stringify(tabsOpened));

		this.TabListener(tab_id)
	
		if(is_new_session) {
			await this.CreateSession()
		} else {
			new Vuex().dispatch('auth/SetSessionKey', this.session_key)
			sessionStorage.setItem('token', this.token)
		}
	}

	async CreateSession() {
		requesting_session = true
	
		return window.api.call('post', '/create-session', {
			account_id: this.account.id,
			tab_id: this.tab_id,
			keep_connected: this.account.keep_connected,
			organization_id: this.organization_id,
		})
			.then(({data}) => {
				if(data.response) {
					already_has_session = true
					
					this.session_key = data.session_key

					new Vuex().dispatch('auth/SetSessionKey', data.session_key)
					
					if(data.new_token) {
						this.is_duplicated = true

						sessionStorage.setItem('token', data.new_token)

						window.axios.defaults.headers.common['Authorization'] = `Bearer ${data.new_token}`;
					} else {
						localStorage.setItem('session_key', this.session_key)
						localStorage.setItem('tab_id', this.tab_id)
					}

					sessionStorage.setItem('is_duplicated', this.is_duplicated)
				}
			})
			.finally(() => {
				requesting_session = false
			})
	}

	async UpdateSession() {
		return window.api.call('post', '/update-session', {
			access_info: {
				organization_id: new Vuex().getters['auth/requester_selected_id'],
			}
		})
	}
	
	async Disconnect(sessions_ids) {
		return window.api.call('post', '/disconnect-sessions', { 
			sessions_ids
		})
	}

	HandleAccount() {
		const account = new Vuex().getters['auth/account']
		const keep_connected = new Vuex().getters['system/keep_connected']

		if(account) {
			return {
				...account,
				keep_connected
			}
		}

		return null;
	}

	async TabListener(tab_id) {
		window.addEventListener('beforeunload', (event) => {
			let tabsOpened = JSON.parse(localStorage.getItem('tabsOpened')) || [];
	
			tabsOpened = tabsOpened.filter((tab) => tab !== tab_id);
	
			localStorage.setItem('tabsOpened', JSON.stringify(tabsOpened));
		});
	}

	GetSessionData() {
		return {
			session_key: this.session_key,
			tab_id: this.tab_id,
			token: this.token,
			is_duplicated: this.is_duplicated
		}
	}

	async InactiveHandlers() {
		if(requesting_session || already_has_session) return;
	
		if(!this.is_redirected) await this.HandleTabId();

		if(this.account.keep_connected?.active) return;

		const KEEP_CONNECTED_TIME = this.account.keep_connected?.time ?? 15;

		const INACTIVITY_TIME = KEEP_CONNECTED_TIME * 60 * 1000;
		const WARNING_TIME = INACTIVITY_TIME - 15 * 1000;
		
		let timeout, warningTimeout;
		let notification;

		if(Notification.permission === "default") {
			Notification.requestPermission();
		}

		function resetTimer() {
			clearTimeout(timeout);
			clearTimeout(warningTimeout);

			warningTimeout = setTimeout(triggerWarningNotification, WARNING_TIME);
			timeout = setTimeout(triggerInactivityAction, INACTIVITY_TIME);
		}

		window.addEventListener("load", resetTimer);
		window.addEventListener("focus", resetTimer);
		window.addEventListener("click", resetTimer);
		window.addEventListener("keydown", resetTimer);
		window.addEventListener("mousemove", resetTimer);

		function triggerWarningNotification() {
			let secondsLeft = 15;

			if(Notification.permission === "granted") {
				notification = new Notification("Aviso de Inatividade", {
					body: `Você será desconectado em ${secondsLeft} segundos!`,
					icon: time_icon,
				});

				notification.onclick = () => {
					notification.close();
					window.focus();
				};
			}
		}

		function triggerInactivityAction() {
			handleUserInactivity();
		}

		async function handleUserInactivity() {
			if(Notification.permission === "granted") {
				notification = new Notification("Aviso de Inatividade", {
					body: `Você ficou inativo por ${KEEP_CONNECTED_TIME} minutos! Desconectando...`,
					icon: time_icon,
				});

				notification.onclick = () => {
					notification.close();
					window.focus();
				};
			}
			
			await new Vuex().dispatch('auth/Logout');
		}

		resetTimer();
	}

	async CloseSession() {
		const url = window.axios.defaults.baseURL

		fetch(`${url}/close-session`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${new Vuex().getters['auth/token']}`,
				'X-Session-Key': this.session_key
			},
			keepalive: true,
		})
	}
}

export default Session;

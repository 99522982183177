import { router } from '../../routes'
import Swal from 'sweetalert2'
import moment from 'moment'

import Session from '@/utils/sessions/Session'

const _ = require('lodash')

const GetAuthAccount = async ({ commit, state, rootState, dispatch }, payload) => {
	await dispatch('system/StartLoading', null, {root: true})

	return window.api.call('get', '/get-auth-account')
        .then( async ({data}) => {
        	if(data.response)
        	{
	            commit('UPDATE_ACCOUNT', data.account)
				localStorage.setItem('account', JSON.stringify(data.account))
				commit('UPDATE_ROLES', data.roles)
				localStorage.setItem('roles', JSON.stringify(data.roles))
				commit('UPDATE_REQUESTERS', data.requesters)
				localStorage.setItem('requesters', JSON.stringify(data.requesters))
				commit('UPDATE_CRMS', data.crms)
				localStorage.setItem('crms', JSON.stringify(data.crms))
				commit('UPDATE_CARDS', data.cards)
				localStorage.setItem('cards', JSON.stringify(data.cards))

				commit('UPDATE_IS_REGISTERED', data.is_registered)
				commit('UPDATE_SUBSCRIPTION_INFO', data.subscription_info)

				await dispatch('DataMigratingOrganizations', data.data_migrating_organizations);

				if(!data.is_registered) {
					await dispatch('RegisterOnGateway', data.account)
				}

				window.Echo.RunPrivate()
				window.Echo.RunGlobal()

				if(state.requester_selected_id) {
					await window.Echo.LeaveOrganizationPool(state.requester_selected_id)
					await window.Echo.RunOrganizationPool()
				}

				if(state.token) {
					await dispatch('LoadSetting', data.account)
				}

				if(rootState.system.keep_connected) {
					await dispatch('RefreshToken')
				}
        	}
        })
		.finally(async () => {
			await dispatch('system/FinishLoading', null, {root: true})
		})
}

const RefreshToken = async ({ commit, state, dispatch }) => {
	const NOW = moment()
	const TOKEN_CHECKED = moment(state.token_checked)
	const ELAPSED = NOW.diff(TOKEN_CHECKED, 'minutes')
	const REFRESH_TTL = parseInt(process.env.VUE_APP_JWT_REFRESH_TTL)

	if(ELAPSED >= REFRESH_TTL) {
		await window.axios.get('/refresh-token')
			.then(({data}) => {
				if(data.response) {
					dispatch('SetToken', data.new_token)
				}
			})
	}
}

const RegisterOnGateway = async ({ commit, state, dispatch }, payload) => {
	if(!payload.email) {		
		return
	}
	
	return window.api.call('post', '/gateway/store-with-professional-trial', {
		account_id: 	payload.id,
		type:			'free',
		name:			payload.name,
		email:			payload.email,
	})
		.then(async ({data}) => {
			if(data.response) {
				commit('UPDATE_IS_REGISTERED', true)
				localStorage.setItem('is_registered', true)

				await dispatch('GetSubscriptionInfo')
			}
		})
}

const LoadSetting = async ({ rootState, dispatch }, payload) => {
	if(payload.setting) {
		await dispatch('system/ToggleThemeCommit', payload.setting.theme, {
		    	root: true
		    })

		await dispatch('system/SetCarouselOrientationCommit', payload.setting.menu_grid_type, {
		    	root: true
		    })

		await dispatch('system/SetIconsSizeCommit', payload.setting.menu_icon_size, {
		    	root: true
		    })

		await dispatch('system/KeepConnected', {
			active: rootState.system.keep_connected.active,
			time: payload.setting.keep_connected_time
		}, {
			root: true
		})

		window.Session = new Session();
		await window.Session.Init();

		if(payload.setting.menu_order) {
			const menu_ordened = _.orderBy(payload.setting.menu_order, 'order', 'asc')
			await dispatch('system/SetMenuOrderCommit', menu_ordened, {
				root: true
			})
		}
		
	}
}

const GetAuthBridgeData = ({ commit, dispatch }, payload) => {
	return new Promise((resolve, reject) => {
		window.axios.post('/get-auth-bridge-data', {
				bridge_key: payload.bridge_key
			})
			.then( async ({data}) => {
				if(data.response) {
					const obj = data.decrypted

					await dispatch('SetToken', obj.token)

					commit('UPDATE_ACCOUNT', obj.account)
					commit('UPDATE_REQUESTERS', obj.requesters)
					commit('UPDATE_CRMS', obj.crms)

					localStorage.setItem('requesters', JSON.stringify(obj.requesters))
					localStorage.setItem('crms', JSON.stringify(obj.crms))
				    localStorage.setItem('account', JSON.stringify(obj.account))
					
					await dispatch('system/KeepConnected', obj.keep_connected, { root: true })

				    await dispatch('LoadSetting', obj.account)

				    resolve(data)
				} else {
					reject(data)
				}
			})
			.catch(({response}) => {
				reject(response)
			})
	})
}

const SetToken = ({ commit }, payload) => {
	const now = moment().format('YYYY-MM-DD HH:mm:ss')

	commit('UPDATE_TOKEN', payload)
	commit('UPDATE_TOKEN_CHECKED', now)

	localStorage.setItem('token', payload)
	localStorage.setItem('token_checked', now)

	sessionStorage.setItem('token', payload)

    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload;
}

const SetSessionKey = ({ commit }, payload) => {
	commit('UPDATE_SESSION_KEY', payload)
	sessionStorage.setItem('session_key', payload)

	window.axios.defaults.headers.common['X-Session-Key'] = payload
}

const SetTabId = ({ commit }, payload) => {
	commit('UPDATE_TAB_ID', payload)
	sessionStorage.setItem('tab_id', payload)
}

const Logout = async ({ commit, state, dispatch }) => {
	if(state.token) {
		await window.axios.post('/logout', {
				token: state.token
			})
			.then(({data}) => {
				dispatch('LogoutContent')
			})
			.finally(() => {
				window.location.href = `${process.env.VUE_APP_LANDING_PAGE_URL}/login`
			})
	}

	router.push({ name: 'GoBack' })
}

const LogoutContent = ({ commit, state }) => {
	commit('UPDATE_TOKEN', null)
	commit('UPDATE_TOKEN_CHECKED', null)
	commit('UPDATE_TAB_ID', null)
	commit('UPDATE_SESSION_KEY', null)
	commit('UPDATE_ACCOUNT', null)
	commit('UPDATE_ROLES', [])
	commit('UPDATE_REQUESTERS', [])
	commit('UPDATE_CRMS', [])
	commit('UPDATE_CARDS', [])
	commit('UPDATE_REQUESTER_ACCOUNT', null)
	commit('UPDATE_RECURRING_ACCESSES', null)
	commit('UPDATE_IS_REGISTERED', null)
	commit('UPDATE_SUBSCRIPTION_INFO', null)
	commit('UPDATE_IP_ADDRESS', null)

	localStorage.removeItem('theme')
	localStorage.removeItem('token')
	localStorage.removeItem('token_checked')
	localStorage.removeItem('account')
	localStorage.removeItem('roles')
	localStorage.removeItem('requesters')
	localStorage.removeItem('crms')
	localStorage.removeItem('cards')
	localStorage.removeItem('requester_selected_id')
	localStorage.removeItem('is_registered')
	localStorage.removeItem('subscription_info')
	localStorage.removeItem('ip_address')
	localStorage.removeItem('tabsOpened')

	sessionStorage.removeItem('token')
	sessionStorage.removeItem('tab_id')
	sessionStorage.removeItem('session_key')
}

const SetRequesterAccount = async ({ commit, state, dispatch }, payload) => {
	const old_requester_selected_id = state.requester_selected_id

	await commit('UPDATE_REQUESTER_ACCOUNT', payload.account_requester_id)

	await localStorage.setItem('requester_selected_id', payload.account_requester_id)

	await window.Echo.LeaveOrganizationPool(old_requester_selected_id)
	await window.Echo.RunOrganizationPool()

	await dispatch('SubscriptionValidate', state);

	if(router?.currentRoute?.name != 'Home' && old_requester_selected_id) {
		router?.push({ name: 'Home' })

		return
	}
}

const SubscriptionValidate = ({state}) => {
	const obj_subscription_info = { ...state.subscription_info };

	if(!obj_subscription_info) return

	if(obj_subscription_info.status === 'active' || obj_subscription_info.status === 'no-subscription' || obj_subscription_info.is_free) return

	let days_left = 999;
	let hours_left = 999;

	days_left = obj_subscription_info.days_left !== null ? obj_subscription_info.days_left : 30;
	hours_left = obj_subscription_info.hours_left !== null ? obj_subscription_info.hours_left : 24;

	let obj_swal = {}
	let swal_question = ''

	if(!obj_subscription_info.has_payment_method) {
		swal_question				= `<br>Deseja cadastrar uma forma de pagamento?`
		
		obj_swal.confirmButtonText 	= 'Sim'
		obj_swal.showCancelButton	= true;
		obj_swal.cancelButtonText 	= 'Mais tarde'
		obj_swal.preConfirm			= () => {
			router.push({ 
				name: 'Profile', 
				params: {
					props_tab: 'payments'
				}
			})
		}
	} else {
		obj_swal.confirmButtonText 	= 'Ok'
	}
	
	if(days_left === 0 && hours_left > 0) {
		Swal.fire({
			html:		`O seu período de testes expirará em <b>${hours_left} hora(s)</b>!
						<br>Cadatre uma forma de pagamento!<br>${swal_question}`,
			icon:		'info',
			title:		'Atenção!',
			...obj_swal,
		})
	} else if (days_left <= 0 && hours_left <= 0) {
		return
	} else if(days_left < 7) {
		Swal.fire({
			html:		`O seu período de testes expira em <b>${days_left} dia(s)</b><br>${swal_question}`,
			icon:		'info',
			title:		'Atenção!',
			...obj_swal
		})
	} else if (days_left === 100) {
		return
	}
}

const UpdateCurrentUrl = ({ commit }, payload) => {
	commit('SET_CURRENT_URL', payload)
}

const AddOnlineAccount = ({ commit }, payload) => {
	commit('ADD_ONLINE_ACCOUNT', payload)
}

const RemOnlineAccount = ({ commit }, payload) => {
	commit('REM_ONLINE_ACCOUNT', payload)
}

const ResetOnlineAccount = ({ commit }, payload) => {
	commit('RESET_ONLINE_ACCOUNT', payload)
}

const DataMigratingOrganizations = ({ commit }, payload) => {
	commit('UPDATE_DATA_MIGRATING_ORGANIZATIONS', payload);
}

const IsRegistered = async ({ commit }, payload) => {
	await window.axios.get('/gateway/is-registered')
		.then(({data}) => {
			commit('UPDATE_IS_REGISTERED', data.response)
		})
}

const GetSubscriptionInfo = async ({commit, state}, payload) => {
	await window.axios.get('/gateway/get-subscription-info')
		.then(async ({data}) => {
			if(data.response) {
				commit('UPDATE_SUBSCRIPTION_INFO', data.subscription_info)

				return
			}

			commit('UPDATE_SUBSCRIPTION_INFO', null)
		})
}

const GetRecurringAccesses = async ({ commit }, payload) => {
	await window.axios.post('/gateway/get-recurring-access', {
		organization_id: parseInt(payload)
	})
		.then(({data}) => {
			if(data.response)
			{
				commit('UPDATE_RECURRING_ACCESSES', data.recurring_accesse)

				return
			}

			commit('UPDATE_RECURRING_ACCESSES', null)
		})
}

const GetWorkgroup = async ({ commit }, payload) => {
	await window.axios.post('/gateway/get-recurring-access', {
		organization_id: parseInt(payload)
	})
		.then(({data}) => {
			if(data.response)
			{
				commit('UPDATE_RECURRING_ACCESSES', data.recurring_accesse)

				return
			}

			commit('UPDATE_RECURRING_ACCESSES', null)
		})
}

export {
	GetAuthAccount,
	RegisterOnGateway,
	SubscriptionValidate,
	GetAuthBridgeData,
	SetToken,
	SetTabId,
	SetSessionKey,
	Logout,
	SetRequesterAccount,
	LoadSetting,
	LogoutContent,
	AddOnlineAccount,
	UpdateCurrentUrl,
	RemOnlineAccount,
	ResetOnlineAccount,
	DataMigratingOrganizations,
	IsRegistered,
	GetRecurringAccesses,
	GetSubscriptionInfo,
	RefreshToken
}
const UPDATE_ACCOUNT 		= (state, payload) => {
	state.account				= payload ? {...payload} : {}
}

const UPDATE_CRMS 			= (state, payload) => {
	state.crms					= payload ? [...payload] : []
}

const UPDATE_REQUESTERS 	= (state, payload) => {
	state.requesters			= payload ? [...payload] : []
}

const UPDATE_ROLES 			= (state, payload) => {
	state.roles	 				= payload ? {...payload} : {}
}

const SET_CURRENT_URL 	= (state, payload) => {
	state.update_current_url    = payload
}

const UPDATE_TOKEN 			= (state, payload) => {
	state.token 				= payload
}

const UPDATE_TOKEN_CHECKED = (state, payload) => {
	state.token_checked = payload
}

const UPDATE_SESSION_KEY = (state, payload) => {
	state.session_key = payload
}

const UPDATE_TAB_ID = (state, payload) => {
	state.tab_id = payload
}

const UPDATE_REQUESTER_ACCOUNT = (state, payload) => {
	state.requester_selected_id = payload
}

const ADD_ONLINE_ACCOUNT 	= (state, payload) => {
	state.online_accounts.push(payload)
}

const REM_ONLINE_ACCOUNT 	= (state, payload) => {
	state.online_accounts 		= state.online_accounts.filter( o => o.id !== payload.id )
}

const RESET_ONLINE_ACCOUNT 	= (state, payload) => {
	state.online_accounts 		= payload ? [...payload] : []
}

const UPDATE_DATA_MIGRATING_ORGANIZATIONS = (state, payload) => {
	state.data_migrating_organizations = payload.length ? [...payload] : []
}

const UPDATE_IS_REGISTERED = (state, payload) => {
	state.is_registered	= payload
}

const UPDATE_RECURRING_ACCESSES = (state, payload) => {
	state.recurring_object = payload
}

const UPDATE_SUBSCRIPTION_INFO = (state, payload) => {
	state.subscription_info = payload
}

const UPDATE_CARDS = (state, payload) => {
	state.cards = payload ? [...payload] : []
}

const UPDATE_IP_ADDRESS = (state, payload) => {
	state.ip_address = payload
}

export {
	UPDATE_ACCOUNT,
	UPDATE_TOKEN,
	UPDATE_TOKEN_CHECKED,
	UPDATE_TAB_ID,
	UPDATE_SESSION_KEY,
	UPDATE_ROLES,
	UPDATE_REQUESTERS,
	UPDATE_CRMS,
	UPDATE_REQUESTER_ACCOUNT,
	ADD_ONLINE_ACCOUNT,
	REM_ONLINE_ACCOUNT,
	SET_CURRENT_URL,
	RESET_ONLINE_ACCOUNT,
	UPDATE_DATA_MIGRATING_ORGANIZATIONS,
	UPDATE_IS_REGISTERED,
	UPDATE_RECURRING_ACCESSES,
	UPDATE_SUBSCRIPTION_INFO,
	UPDATE_CARDS,
	UPDATE_IP_ADDRESS
}
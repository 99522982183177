<template>
    <div class="container-fluid">
		<div class="row align-items-center justify-content-between">
            <DataTables
                v-if="!is_load && attendances?.length != 0"
                class="col-12"
                v-model="attendances"
                :headers="headers"
                :trButton="true"
                :prop_sortedBy="sortedBy"
                @by-click-on-tr="SelectAttendance"
            >
                <template v-slot:body="{ item }">
                    <td class="text-muted align-middle" scope="col">
                        {{ item.date }}
                    </td>
                    <td class="text-muted align-middle" scope="col">
                        {{ item.time }}
                    </td>
                    <td class="align-middle" scope="col">
                        <b>{{ item.proceeding_name ?? 'Sem procedimento vinculado' }}</b>
                    </td>
                    <td scope="col" class="text-muted align-middle">
                        <b>{{ item.status_descricao.toUpperCase() }}</b>
                    </td>
                    <td scope="col" class="text-muted align-middle">
                        <button v-if="item.pdf_url"
							content="Visualizar laudo"
							v-tippy="{ arrow: true }"
							class="btn btn-light-info px-3 py-2 mx-2"
							@click.stop="OpenModalReport(item.pdf_url)">
							<i class="m-1 fal fa-file-medical font-20"></i>
						</button>
                        <button v-if="item.dicom_images_url"
							content="Visualizar Imagens DICOM"
							v-tippy="{ arrow: true }"
							class="btn btn-light-info px-3 py-2 mx-2"
							@click.stop="OpenExternalLink(item.dicom_images_url)">
							<i class="m-1 fal fa-file-image font-20"></i>
						</button>
                    </td>
                </template>
            </DataTables>
            <div v-else
                    class="mx-auto my-5">
                Não há registros no prontuário.
            </div>
        </div>

        <AttendanceModal 
            :origin="'history'"
			:attendance_id="selected_attendance_id"
		/>

		<!-- MODAL LAUDO -->
		<div
			class="modal fade"
			id="report-modal"
		>
			<div class="modal-dialog">
				<div class="modal-content p-3 d-flex">
					<div class="align-self-end">
						<button
							id="close-modal"
							class="btn btn-light btn-sm m-2"
							@click="CloseModal('#report-modal')"
						>
							<i class="fal fa-times"></i>
						</button>
					</div>
					<div class="modal-header mb-4">
						<h3 class="m-0 text-center">
							Visualização do laudo
						</h3>
					</div>
					<div class="modal-body d-flex justify-content-center" ref="report_content">
						
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DataTables from '../../shared/DataTables'
import AttendanceModal from '../../shared/AttendanceModal'
import moment from 'moment'
const _ = require('lodash')
import Swal from 'sweetalert2'

export default {
    name:   'History',
    components: {
        DataTables,
        AttendanceModal,
    },
    props: {
        patient:        {
            type:       Object,
            required:   true,
            default:    null
        }
    },
    data() {
        return {
            is_load:        false,
            history:        null,
			app_url:		process.env.VUE_APP_APP_URL,
			report_url:		'',
			ended_report:	'',
			report_code:	'',
            headers: [
                { text: 'Data', value: 'data' },
                { text: 'Hora', value: 'hora' },
                { text: 'Procedimento', value: 'proceeding' },
                { text: 'Status', value: 'status_descricao' },
                { text: 'Ações', value: 'actions' },
            ],
            timeline_data: 	{
                patient:      	{},
                logs:         	[],
                journey_id:     null,
                scheduling_id:  null
            },
            sortedBy:		{
                column: 		'data',
                direction:		'DESC',
                icon:			'fa-chevron-down'
            },
            selected_attendance_id:    null,
            attendances: []
        }
    },
    computed: {
        ...mapGetters('auth', [
            'requester_selected_id'
        ])
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        OpenExternalLink(link) {
            window.open(link, '_blank');
        },
        GetPatientAttendances() {
			const vm = this

			vm.StartLoading()
            
            vm.attendances = []

			return window.api.call('post', '/get-attendances', {
				account_id: vm.requester_selected_id,
				patient_id: `${vm.patient.id}`,
                light_data: true
			})
				.then(({data}) => {
					if(data.response) {
                        vm.attendances = data.attendances
					}
				})
				.finally(() => {
					vm.FinishLoading()
				})
		},
        SelectAttendance(attendance) {
            this.selected_attendance_id = attendance.id

            window.jQuery('#modal-attendance').modal('show')
        },
        ShowTimeline(attendance) {
            this.timeline_data = {
                patient:        {},
                logs:           [],
                attendance_id:  null
            }
            
            if(attendance?.logs?.length) {
                this.timeline_data.logs      		= attendance.logs
                this.timeline_data.patient     		= attendance.patient
                this.timeline_data.attendance_id  	= attendance.id

                window.jQuery('#modal-timeline').modal('show')

                return;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Não há registros para este agendamento.',
                    toast: true,
                    position: 'top',
                    timer: 5000,
                    timerProgressBar: true,
                })
            }

            window.jQuery('#modal-timeline').modal('hide')
        },
        PrintReport()
        {
            const vm  = this
            
            const fn  = (event) => {
                if (event.data.print)
                {
                    vm.StartLoading()

                    window.removeEventListener('message', fn)

                    win.close()
                }
            }
            
            vm.FinishLoading()

            const win     	= window.open(`${this.app_url}/consultar_laudos/visualizar/${this.report_code}?print`, '_blank')
            win.mainWindow  = window
            window.addEventListener('message', fn)
        },
		async OpenModalReport(link) {
            this.$refs.report_content.innerHTML = `<embed id='report-body' src='${link}' type='application/pdf'>`

			window.jQuery('#report-modal').modal('show')
                .on('shown', () => {
                    window.jQuery('body').addClass('modal-open')
                })
		},
		CloseModal(modal_id)
		{
			this.report_code = ''
			this.report_url = ''

			window.jQuery(modal_id).modal('hide')
				.on('hidden.bs.modal', () => {
					window.jQuery('body').removeClass('modal-open')
				})
		}
    },
    async beforeMount()
    {
        this.is_load = true
        await this.GetPatientAttendances()
        this.is_load = false
    }
}
</script>

<style lang="css">
#report-modal .modal-dialog
{
	max-width: 1100px !important;
}

#report-modal .modal-content
{
	min-width: 600px;
	min-height: 600px;
}

#report-body
{
	border: unset; 
    width: 1045px;
    height: 810px;
}

#holder img.logo
{
	width: 50% !important;
}

#holder .paciente div
{
	line-height: 1.8rem !important;
}

#holder img.border-paciente
{
	width: 100%;
	margin: 1rem 0 !important;
}

#holder .content
{
	margin-top: .5rem;
	visibility: unset !important;
}

#holder .content span a.a-impressao
{
	color: #000;
	cursor: pointer;
}

#holder .content span a.a-impressao:hover
{
	color: #000 !important;
	text-decoration: underline !important;
}

#holder p.titulo
{
	font-weight: bold;
	text-align: center;
	font-size: 1.7rem;
}

#holder p.subtitulo
{
	font-weight: bold;
	text-align: center;
	font-size: 1.5rem;
}

#holder p.estrutura
{
	font-weight: 700;
	font-size: 1.3rem;
}

#holder 
p.tracinho.impressao_diagnostica.conclusao,
p.tracinho.diagnostico_diferencial_patologico
{
	font-weight: bold;
}

#holder 
p.tracinho.tecnica_exame_normal,
p.tracinho.diagnostico_diferencial_normal
{
	font-weight: 400;
}

#holder .carimbo
{
	margin-top: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

#holder #footer
{
	width: 100%;
	margin: 3rem 2rem 0 0;
	padding-top: 2px;
	border-top: 2px solid #323232;
	display: flex;
	justify-content: flex-end;
}

#holder #footer p
{
	margin: 0;
}

#holder #footer p.codigo
{
	background-color: #fff;
	margin: -16px 10px -2px 0 !important;
	padding: 0 8px;
	width: 304px;
}
</style>
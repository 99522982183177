<template>
	<div class="col-12 px-0">
		<div class="col-12 px-0 mb-3 d-flex justify-content-between align-items-center">
			<h5 class="mb-0">Sessões ativas</h5>
			<div class="col-auto px-0">
				<button
					class="btn btn-outline-danger disconnect-button"
					@click="DisconnectAllSessions()"
				>
					Encerrar todas as sessões
				</button>
			</div>
		</div>
		<SessionsList
			ref="sessions_list"
		/>

		<hr class="my-4">

		<h5>Configurações da sessão</h5>
		<div class="col-12 px-0 d-flex">
			<div class="col px-0 form-group d-flex align-items-center">
				<label
					class="px-0 mr-3"
					for="keep_connected_time"
				>
					Tempo para conexão temporária:
				</label>
				<input
					id="keep_connected_time"
					type="phone"
					class="col-6 col-md-2 form-control py-3"
					v-model.number="keep_connected_time"
					v-tippy="{ arrow: true, content: 'Tempo em minutos', followCursor: true }"
					placeholder="15"
					v-debounce:500="SaveKeepConnectedTime"
				>
			</div>
		</div>
		<p class="mb-0">
			<small>Essa configuração entrará em vigor se você acessar o sistema com a opção "Manter conectado" DESMARCADA.</small>
		</p>

		<hr class="my-4">

		<AccessHistory />
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import DataTables from '@/components/shared/DataTables'
import SessionsList from '@/components/shared/SessionsList'
import AccessHistory from './components/AccessHistory'

export default {
	name: 'Accesses',
	components: {
		DataTables,
		SessionsList,
		AccessHistory,
	},
	data () {
		return {
			keep_connected_time: 15,
		}
	},
	computed: {
		...mapGetters('auth', [
				'tab_id'
			]
		),
		...mapGetters('system', [
				'keep_connected'
			]
		)
	},
	watch: {
		keep_connected: {
			handler(val) {
				if(val) {
					this.keep_connected_time = val.time
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		DisconnectAllSessions() {
			Swal.fire({
				title: 'Encerrar Sessões!',
				html: 'Realmente deseja encerrar todas as sessões?<br><b>(Incluindo a sessão atual)</b>',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sim',
				cancelButtonText: 'Não',
				reverseButtons: true,
			})
				.then((result) => {
					if(result.isConfirmed) {
						this.$refs.sessions_list.DisconnectAll()
					}
				})
		},
		SaveKeepConnectedTime() {
			if(!this.keep_connected_time) return;

			return window.api.call('post', '/save-keep-connected-time', {
				keep_connected_time: this.keep_connected_time
			})
			.then(({data}) => {
				if(data.response) {
					Swal.fire({
						title: 'Salvo',
						icon: 'success',
						toast: true,
						position: 'top',
						timer: 3000,
						timerProgressBar: true,
						showConfirmButton: false
					})

					return;
				}

				Swal.fire({
					title: 'Erro',
					text: 'Não foi possível salvar a configuração',
					icon: 'error'
				})
			})
		}
	},
	mounted() {
	}
}
</script>

<style lang="css" scoped>
.sessions-container {
	gap: 10px;
	max-height: 600px;
	overflow: auto;
}

::v-deep(.session-card) {
	border-radius: 1rem;
	max-width: 335px;
	min-width: 300px;
}

::v-deep(.disconnect-button) {
	border-radius: .5rem;
	padding: .5rem !important;
}

.btn-outline-danger {
	color: #ff58696b;
	border-color: #ff58696b;
}

.btn-outline-danger:hover {
	color: var(--light);
	background-color: #e04d5b6b;
	border-color: #e04d5b6b;
}

@media screen and (max-width: 768px) {
	.session-card {
		max-width: 500px;
	}
}
</style>
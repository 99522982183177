import Vue from 'vue'
import Vuex from './vuex'
import _Echo from 'laravel-echo'
import Swal from 'sweetalert2'
import { router } from './routes.js'
import _ from 'lodash'

class Echo
{
	constructor() {
		this.token 				= null
		this.account			= null
		this._echo 				= null

		this.Toast 				= Swal.mixin({
				toast: 				true,
				position: 			'top-end',
				showConfirmButton: 	false,
				timer: 				3000,
				timerProgressBar: 	true,
				didOpen: (toast) => {
				    toast.addEventListener('mouseenter', Swal.stopTimer)
				    toast.addEventListener('mouseleave', Swal.resumeTimer)
				}
			})
	}

    EchoInstance() {
		this.token 				= new Vuex().getters['auth/token']
		this.account			= new Vuex().getters['auth/account']

        if(this.token) {
            return new _Echo({
                broadcaster: 	'pusher',
                key:            process.env.VUE_APP_WEBSOCKET_APP_KEY,
                wsHost:         process.env.VUE_APP_WEBSOCKET_APP_HOST,
                wsPort:         +process.env.VUE_APP_WEBSOCKET_APP_PORT,
                wssPort:        +process.env.VUE_APP_WEBSOCKET_APP_PORT,
                forceTLS:  		process.env.VUE_APP_WEBSOCKET_APP_ENCRYPTED === 'true',
                encrypted:      process.env.VUE_APP_WEBSOCKET_APP_ENCRYPTED === 'true',
                cluster:        process.env.VUE_APP_WEBSOCKET_APP_CLUSTER,
				disableStats:   false,
				enabledTransports: ['ws', 'wss'],
				authEndpoint: 	`${process.env.VUE_APP_WEBSOCKET_URL}/api/broadcasting/auth`, 
				auth: {
					headers: {
						Authorization: `Bearer ${ this.token }`,
						Accept:        'application/json',
					}
                }
            })
        }

        console.log('Houve um erro ao iniciar o ECHO.')

        return null
    }

    LeaveOrganizationPool(id) {
    	if(!this._echo) {
	    	this._echo      		= this.EchoInstance()
    	}

		if(this._echo) {
			this._echo.leave(`organization-pool.${ id || new Vuex().getters['auth/requester_selected_id'] }`)
		} else {
			console.log('Houve um erro ao iniciar o ECHO.')
		}
    }

	RunGlobal() {
		if(!this._echo) {
			this._echo 				= this.EchoInstance()
		}

		if(this._echo) {
			this._echo.join('presence-global')
				.here((users) => {
				})
				.joining((user) => {
				})
				.leaving((user) => {
				});
		} else {
	    	console.log('Houve um erro ao iniciar o ECHO global.')
	    } 
	}

    RunPrivate() {
    	if(!this._echo) {
	    	this._echo      		= this.EchoInstance()
    	}

		if(this._echo) {
			this._echo.private(`account.${ this.account.id }`)
				.listen('KickUser', (e) => {
					Swal.fire({
						title: 'Atenção !',
						text: e.message,
						icon: 'warning',
						showCancelButton: false,
						confirmButtonText: 'OK',
						showLoaderOnConfirm: true,
					  	allowOutsideClick: () => false
					}).then((result) => {
						if(result.isConfirmed)
						{
							new Vuex().dispatch('auth/Logout')
						}
					})
				})
				.listen('PendingTemplateUpdate', (e) => {
					const organization_id 	= new Vuex().getters['auth/requester_selected_id']

					// só recebe notificação se estiver trabalhando na org 
					if ( e.requester.id && e.requester.id == organization_id ) {
						Swal.fire({
							icon: 		'info',
							title: 		`<b>${e.account.name}</b> mudou o template padrão de <b>${e.requester.name}</b>`,
							toast: 		true,
							position: 	'top',
							timer: 		8000
						})

						window.api.call('get', '/get-auth-account')
							.then((data) => {
								if(data.response)
								{
									new Vuex().commit('UPDATE_REQUESTERS', data.requesters)
									localStorage.setItem('requesters', JSON.stringify(data.requesters))
								}
							})
					}

				})
				.listen('SessionTerminated', (e) => {
					const current_tab_id = new Vuex().getters['auth/tab_id']

					const is_same_tab = current_tab_id === e.session_id

					if(is_same_tab) {
						delete window.axios.defaults.headers.common['X-Session-Key']
						delete window.axios.defaults.headers.common['Authorization']

						sessionStorage.removeItem('session_key')

						Swal.fire({
							title: 'Atenção!',
							text: 'Sessão finalizada, faça login novamente.',
							icon: 'info',
							showCancelButton: false,
							confirmButtonText: 'OK',
							showLoaderOnConfirm: true,
						  	allowOutsideClick: () => false
						}).then((result) => {
							this.Logout()
						})

						setTimeout(() => {
							this.Logout()
						}, 30000)
					}
				})
		} else {
	    	console.log('Houve um erro ao iniciar o ECHO privado.')
	    }  
    }

    async RunOrganizationPool() {
		const organization_id 	= new Vuex().getters['auth/requester_selected_id']

		let obj_subscription_info 	= { ...new Vuex().getters['auth/subscription_info'] } || null;

		if(!obj_subscription_info) return

		if(!this._echo) {
	    	this._echo      		= await this.EchoInstance()
    	}

		if(this._echo && organization_id) {
			this._echo.join(`organization-pool.${ organization_id }`)
	            .here(async (accounts) => {
	            	//TRAZ TODO MUNDO QUE TÁ LOGADO LOGO QUANDO ENTRA NO CANAL
					
					await this.ValidateSubscription(obj_subscription_info)

					new Vuex().dispatch('auth/ResetOnlineAccount', accounts)
			    })
			    .joining((account) => {
			    	//DISPARA SEMPRE QUE ENTRA ALGUEM NO CANAL
			        let name 		= account.name.split(' ')
			        let full_name	= name[0]
			        full_name 		+= ` ${name[name.length - 1].charAt(0).toUpperCase()}.`

					// REMOVIDO O DEDO DURO
			        // this.Toast.fire({
			        // 	icon: 		'success',
			        // 	title: 		`${full_name} acabou de entrar.`
			        // })

			        new Vuex().dispatch('auth/AddOnlineAccount', account)
			    })
			    .leaving((account) => {
			    	//DISPARA SEMPRE QUE ALGUEM SAI DO CANAL
			        let name 		= account.name.split(' ')
			        let full_name	= name[0]
			        full_name 		+= ` ${name[name.length - 1].charAt(0).toUpperCase()}.`

					// REMOVIDO O DEDO DURO
			        // this.Toast.fire({
			        // 	icon: 		'error',
			        // 	title: 		`${full_name} acabou de sair.`
			        // })

			        new Vuex().dispatch('auth/RemOnlineAccount', account)
			    })
			    .error((error) => {
			    	//QUANDO DA ERRO, OBVIO
			        console.error(error);
			    });

				this._echo.channel(`organization-pool.${organization_id}`)
					.listen('FileReadyEvent', (e) => {
						const organization_id 	= new Vuex().getters['auth/requester_selected_id']

						new Vuex().dispatch('auth/UpdateCurrentUrl', true)
                    
                        if(e.message) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Ops!',
                                html: e.message,
                            })

                            return
                        }

                        Swal.fire({
                            icon:               'info',
                            title: 		        'Arquivo pronto.',
                            toast: 		        true,
                            position: 	        'top',
                            timer: 		        3000,
                            timerProgressBar:   true
                        })
					})
					.listen('FileProgressEvent', (e) => {
						new Vuex().dispatch('auth/UpdateCurrentUrl', true)
					})
					.listen('DataMigrationStarted', (e) => {
						let new_data_migrating_organizations = [...new Vuex().getters['auth/data_migrating_organizations']]

						new_data_migrating_organizations.push(parseInt(organization_id));

						new_data_migrating_organizations = _.uniq(new_data_migrating_organizations)

						new Vuex().dispatch('auth/DataMigratingOrganizations', new_data_migrating_organizations)
					})
					.listen('DataMigrationFinished', (e) => {
						const new_data_migrating_organizations = [...new Vuex().getters['auth/data_migrating_organizations']]
							.filter((org) => parseInt(org) !== parseInt(organization_id))
						
						new Vuex().dispatch('auth/DataMigratingOrganizations', new_data_migrating_organizations)
					})
	    } else {
	    	console.log('Houve um erro ao iniciar o ECHO da organização.')
	    }        
    }

	Logout() {
		window.location = process.env.VUE_APP_LANDING_PAGE_URL + '/login'
	}

	async GetAdministrators(org_id)
	{
		let adms = []

		new Vuex().dispatch('system/StartLoading')

		await window.api.call('post', '/get-administrators', {
			organization_id: org_id
		})
			.then(({data}) => {
				if(data.response)
				{
					adms = data.administrators
				}
			})
		
		new Vuex().dispatch('system/FinishLoading')

		return adms
	}

	async ValidateSubscription(obj_subscription_info) {
		if(obj_subscription_info && obj_subscription_info.status === 'past_due') {
			let obj_swal = {}

			if(!obj_subscription_info.has_payment_method) {
				obj_swal.html				= `Seu período de teste já expirou!<br>
					<br>Cadastre uma forma de pagamento para continuar utilizando todo o sistema!`
				obj_swal.confirmButtonText 	= 'Ir para Pagamento'
				obj_swal.showCancelButton	= true;
				obj_swal.cancelButtonText 	= 'Cancelar'
				obj_swal.preConfirm			= () => {
					router.push({ 
						name: 'Profile', 
						params: {
							props_tab: 'payments'
						}
					})
				}
			} else {
				obj_swal.html				= `Seu período de teste já expirou!<br>
												Ainda não conseguimos fazer a cobrança, contate o suporte!`
				obj_swal.confirmButtonText 	= 'Ok'
			}

			Swal.fire({
				icon:		'warning',
				title:		'Atenção!',
				...obj_swal,
			})
		} 
	}
}

export default Echo
<template>
	<div class="bg-white container-fluid px-5 pb-5">
		<div class="row">
			<div class="col-12">
				<div>
					<table class="w-100" :style="{visibility: visibility}">
						<thead>
							<tr>
								<td>
									<div class="row">
										<div class="col-12">
											<div class="position-relative mb-3">
												<div v-if="account_logo" class="logo-container">
													<img :src="account_logo" style="height: 100px; position: absolute; top: 8px;">
												</div>
												<div class="logo-instant-container">
													<img :src="logo_instant" class="w-100" style="height: 44px">
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="position-relative">
									<table class="w-100 mb-5 main-content">
										<thead>
											<tr>
												<td>
													<div class="form-row mt-3 flex-nowrap text-dark align-items-start header-container" v-if="patient">
														<div class="col-auto mt-2">
															<div 
																class="shadow" 
																style="width: 165px; height: 155px; border: 1px solid #ccc; border-radius: .5rem;"
															>
																<img 
																	:src="patient_avatar" 
																	style="width: 100%; height: 100%; object-fit: cover; border-radius: .5rem;"
																>
															</div>
														</div>
														<div class="col ml-4 h-100 d-flex flex-column justify-content-between">
															<div class="row">
																<div class="col-12 px-0">
																	<span class="spotlight"><b> {{ patient.name }} </b></span>
																</div>
															</div>
															<div class="row">
																<div class="col-auto px-0">
																	RG: <span :class="patient.rg ? 'font-weight-bold' : 'unknown'">{{ patient.rg ? patient.rg.toUpperCase() : 'DESCONHECIDO' }}</span>
																	<span v-if="patient.rg_uf != 'DESCONHECIDO'" :class="patient.rg_uf ? 'font-weight-bold' : 'unknown'">{{ patient.rg_uf ? patient.rg_uf.toUpperCase() : '' }}</span>
																</div>
																<div class="col-auto px-0">
																	CPF: <span :class="patient.cpf ? 'font-weight-bold' : 'unknown'">{{ patient.cpf ? patient.cpf.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
																<div class="col-auto px-0">
																	SUS: <span :class="patient.sus ? 'font-weight-bold' : 'unknown'">{{ patient.sus ? patient.sus.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
																<div class="col-auto px-0">
																	RH: <span :class="patient.rh ? 'font-weight-bold' : 'unknown'">{{ patient.rh ? patient.rh.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
															</div>
															<div class="row">
																<div class="col-auto px-0">
																	DATA DE NASCIMENTO: <span :class="patient_birthdate ? 'font-weight-bold' : 'unknown'">{{ patient_birthdate }}</span>
																	<span v-if="patient.date_of_birth" :class="patient_age ? 'font-weight-bold' : 'unknown'"> ({{ patient_age }})</span>
																</div>
																<div class="col-auto px-0">
																	SEXO: <span :class="patient.sex ? 'font-weight-bold' : 'unknown'">{{ patient.sex ? (patient.sex === 'M' ? 'MASCULINO' : 'FEMININO') : 'DESCONHECIDO' }}</span>
																</div>
																<div class="col-auto px-0">
																	TELEFONE: <b> {{ patient.cellphone ?? 'DESCONHECIDO' }} </b>
																</div>
																<div v-if="is_endoscopy_modality && patient.mother_name" 
																	class="col-auto px-0">
																	NOME MÃE: <b>{{ patient.mother_name.toUpperCase() }}</b>
																</div>
															</div>
															<div class="row">
																<div v-if="partnership"
																	class="col-auto px-0">
																	CONVÊNIO: <b> {{ partnership.toUpperCase() }}</b>
																</div>
															</div>
															<div class="row">
																<div class="col-auto px-0">
																	SOLICITANTE: <span :class="indicator ? 'font-weight-bold' : 'unknown'">{{ indicator ? indicator.toUpperCase() : 'DESCONHECIDO' }}</span>
																</div>
															</div>
														</div>
													</div>
													<div class="row">
														<div class="col-12">
															<hr style="border-top: 2px solid #333;" />
														</div>
													</div>
												</td>
											</tr>
										</thead>
										<tbody>
											<div v-if="with_logs && scheduling?.logs?.length">
												<tr 
													v-for="(log, index) in scheduling.logs"
													:key="`${log.author}${index}`"
												>
													<td>
														<div class="row">
															<div class="col-12">
																<span class="position-absolute ps-b-0 ps-t-0 ps-l-0 border-left bg-dark" style="margin-left: 38px;"></span>
																<div class="py-4 pl-6">
																	<span 
																		class="position-absolute ps-l-0 ml-4"
																		style="font-size: 3rem; margin-top: -28px" 
																		:class="{
																			'text-primary': index === (scheduling.logs.length - 1),
																			'text-secondary': index !== (scheduling.logs.length - 1)
																		}"
																	>●</span>
																	<div class="col-12 px-0 d-flex flex-wrap">
																		<div class="col-12 px-0 d-flex">
																			<div class="col-3">
																				<span class="text-dark font-14">
																					<b>{{ log.status }}</b>
																				</span>
																			</div> 
																			<div class="col-9">
																				<span class="text-dark">
																					{{ log.date_time }}
																				</span>
																			</div>
																		</div>
																		<template v-if="log.logType === 'user'">
																			<div class="col-12 px-0 d-flex">
																				<div class="col-3">
																					<span class="text-muted font-14">Autor</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">{{ log.author }}</span>
																				</div>
																			</div>
																			<template v-if="log.description">
																				<div class="col-12 px-0 d-flex">
																					<div class="col-3">
																						<span class="text-muted font-14">Descrição</span>
																					</div>
																					<div class="col-9">
																						<span class="text-dark">{{ log.description }}</span>
																					</div>
																				</div>
																			</template>
																			<div class="col-12 px-0 d-flex">
																				<div class="col-3">
																					<span class="text-muted font-14">Procedimento</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">{{ log.proceeding ?? 'Sem procedimento vinculado'}}</span>
																				</div>
																			</div>
																		</template>
																		<template v-else>
																			<div class="col-12 px-0 d-flex">
																				<div class="col-3">
																					<span class="text-muted font-14">Autor</span>
																				</div>
																				<div class="col-9">
																					<span class="text-dark">SISTEMA</span>
																				</div>
																			</div>
																		</template>
																	</div>
																</div>
															</div>
														</div>
													</td>
												</tr>
											</div>
											<div v-else>
												<scheduling-card 
													v-if="scheduling"
													:scheduling_base="scheduling"
													:print_component="print_component"
												/>
											</div>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>
									<footer>
										<div class="row position-relative">
											<div class="col-12" v-if="instance">
												<p class="text-center text-dark m-0">
													{{ instance.config.rodape_sup}}
												</p>
											</div>
											<div class="col-12" v-if="instance">
												<p class="text-center text-dark m-0">
													{{instance.config.rodape_inf}}
												</p>
											</div>
										</div>
									</footer>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const moment 		= require('moment')
	const _ 			= require('lodash')

	import { mapActions, mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
	import SchedulingCard from './SchedulingCard'

	export default {

		name: 'PrintJourneyScheduling',
		props: {

		},
		components: {
			SchedulingCard
		},
		data () {
			return {
				attendance_id: 		null,
				journey_id: 		null,
				scheduling: 		null,
				patient: 			null,
				intake_form: 		null,
				logo_instant: 		require('../../assets/img/header-impressao.png'),
				border_patient: 	require('../../assets/img/border-paciente.jpg'),
				default_avatar: 	window.defaultAvatar,
				visibility:  		'hidden',
				print_component: 	true,
				indicator:	{}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'account', 'requesters'
			]),
			with_logs() {
				return !!this.attendance_id
			},
			current_link() {
				return window.location.href
			},
			instance() {
				return this.requesters.filter(r => parseInt(r.account_requester_id) === parseInt(this.requester_selected_id))[0].instance
			},
			account_logo() {
				return this.instance.config.logo_url
			},
			patient_avatar() {
				return this.patient && this.patient.avatar ? this.patient.avatar : this.default_avatar
			},
			timeline_log() {
	            if(this.scheduling && this.scheduling.logs && this.scheduling.logs.length > 0) {
	                const logs = this.scheduling.logs.slice()

	                return _.orderBy(logs, [(el => moment(el.data, 'YYYY-MM-DD HH:mm:ss'))], ['asc'])
	            } else {
	                return []
	            }
	        },
			first_log_date() {
	            if(this.timeline_log.length > 0) {
	                return this.FormatDate(_.last(this.timeline_log).data, 'DD/MM/YYYY')
	            } else {
	                return ''
	            }
	        },
			is_endoscopy_modality() {
				const ENDOSCOPY_MODALITY_ID = 27

				return this.scheduling?.intake_form?.proceedings?.filter(p => p.modality_id == ENDOSCOPY_MODALITY_ID).length > 0 ?? false
			},
			patient_birthdate() {
				let birthdate = 'DESCONHECIDO';

				if(!this.patient.date_of_birth) return birthdate

				birthdate = moment(this.patient.date_of_birth).format('DD/MM/YYYY')

				return birthdate
			},
			patient_age() {            		
	            if(this.patient && this.patient.date_of_birth) {
	                const dob 		= moment(this.patient.date_of_birth, 'YYYY-MM-DD')
	                const today 	= moment()

	                const years 	= today.diff(dob, 'year')
	                dob.add(years, 'years')

	                const months 	= today.diff(dob, 'months')
	                dob.add(months, 'months')
	                
	                const days 		= today.diff(dob, 'days')

	                let result 		= ''

	                if(years > 0) {
	                   result =  `${years} ANO${years > 1 ? 'S' : ''}`

	                } else if (years === 0 && months > 0) {
	                    result = `${months} MES${months > 1 ? 'ES' : ''} e ${days} DIA${days > 1 ? 'S' : ''}`

	                } else if (months === 0 && days > 0) {
	                    result = `${days} DIA${days > 1 ? 'S' : ''}`
	                } else {
	                    result = '0 DIAS'
	                }
	                
	                return result
	            } else {
	                return 'DESCONHECIDO'
	            }
			},
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetScheduling() {
				this.StartLoading()

				let query_obj = {}

				if(this.attendance_id) {
					query_obj = {
						attendance_id: this.attendance_id
					}
				} else {
					query_obj = {
						journey_id: this.journey_id
					}
				}

				return window.api.call('post', '/get-attendances', {
						account_id: this.requester_selected_id,
						...query_obj
					})
					.then(({data}) => {
						if(data.response) {
							this.scheduling = data.scheduling

							if(this.scheduling) {
								this.intake_form = this.scheduling.intake_form
								this.patient = this.scheduling.header.patient
								this.partnership = this.scheduling.header.partnership
								this.indicator = this.scheduling.header.indicator

								this.scheduling.logs = _.orderBy(this.scheduling.logs, 'id', 'asc')
							}
						} else {
							Swal.fire({
		                        icon:   'error',
		                        text:   'Houve um erro ao obter, contate o suporte.',
		                        title:  'Opss...'
		                    })
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			FormatDate(value, fmt)
			{
	            const m 		= moment(value, 'YYYY-MM-DD HH:mm:ss')

	            return m.format(fmt)
	        },
			UpdateIndicator(param) {
				this.indicator = param
			},
		},
		mounted() {
			const vm = this
			
			vm.attendance_id = vm.$route.query.attendance_id || null;
			vm.journey_id = vm.$route.query.journey_id || null;

	        window.addEventListener('afterprint', () => {
	            if (window.mainWindow){
	                window.mainWindow.postMessage({print: true})
	            }
	        })

			vm.GetScheduling()
				.finally( async () => {
					await vm.$nextTick()
	                await vm.$nextTick()
	                await vm.$nextTick()
	                setTimeout(async () => {
	                    vm.visibility = 'visible'
	                    await vm.$nextTick()
	                    window.print()
	                }, 1000)
				})

			const style_print = window.jQuery('<link>', {
				rel: 'stylesheet',
				type: 'text/css',
				href: require('@/assets/sass/portrait.scss')
			});
			
			style_print.appendTo('head');
		}
	}
</script>

<style lang="css" scoped>
	.row {
		gap: 0px 20px;
	}

	.unknown {
		opacity: .5;
	}

	.qrcode {
		position: absolute;
		bottom: 0px;
		right: 0px;
	}

	.spotlight {
		font-size: 1.75rem;
	}

	.logo-instant-container {
		position: absolute;
		top: 68px;
		width: 100%;
	}
	
	.header-container {
		height: 161px;
	}

	.main-content {
		position: absolute;
		top: 95px;
	}

	table {
		page-break-after: auto;
	}

	table thead {
		display: table-header-group;
	}

	table tfoot  {
		display: table-footer-group;
	}

	@media print {
		body {
			width: 21cm;
			height: 29.7cm;
			margin: 0 !important;
			padding: 0 !important;
			overflow: hidden;
			background-color: red;
		}

		html, body {
			height:100vh; 
			margin: 0 !important; 
			padding: 0 !important;
			overflow: hidden;
		}

		@page  {
			margin: 2mm 4mm 10mm 4mm;
		}

		footer {
			padding: 0 35px;
			position: fixed;
			right: 0;
			left: 0;
			bottom: 0;
		}
	}
</style>
export default {
	account:						JSON.parse(localStorage.getItem('account')),
	crms: 							JSON.parse(localStorage.getItem('crms')),
	requesters: 					JSON.parse(localStorage.getItem('requesters')),
	token: 							sessionStorage.getItem('token') ?? localStorage.getItem('token'),
	token_checked: 					localStorage.getItem('token_checked'),
	tab_id: 						sessionStorage.getItem('tab_id') ?? localStorage.getItem('tab_id') ?? null,
	session_key: 					sessionStorage.getItem('session_key') ?? localStorage.getItem('session_key') ?? null,
	roles: 							JSON.parse(localStorage.getItem('roles')),
	requester_selected_id: 			localStorage.getItem('requester_selected_id'),
	online_accounts: 				[],
	data_migrating_organizations:	[],
	is_registered:					false,
	recurring_object:				null,
	subscription_info:				null,
	cards:							typeof localStorage.getItem('cards') !== undefined ? JSON.parse(localStorage.getItem('cards')) : [],
	update_current_url:				false,
	ip_address:						null,
}
<template>
	<div>
		<div class="container">
			<div class="row align-items-center vh-md-80 justify-content-start flex-column">
				<div class="col-12">
					<div class="d-block mx-auto pb-4 pb-md-5 text-md-center max-500 pr-6 pr-md-0">
						<span>{{ label }}</span>
						<h4 class="font-24 font-md-32 m-0">Definir paciente</h4>
					</div>
				</div>
				<div class="col-12 col-md-8">
					<div class="form-row align-items-center">
						<div class="col-md">
							<div class="form-group position-relative m-md-0">
								<input 
									type="search"
									autofocus
									ref="patient_search"
									class="form-control pl-5" 
									placeholder="Pesquisar por nome, telefone ou documento"
									v-model="search"
								>
								<i class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"></i>						
							</div>
						</div>
						<div class="col-md-auto">
							<button 
								class="btn btn-block btn-primary"
								@click="NewPatientModal"
								:disabled="!search"
							>
								{{ form_type == 'pre' ? 'Pré' : ''}} Cadastrar
							</button>
						</div>
					</div>

					<div class="row align-items-center mt-4">
				
						<transition-group mode="out-in" name="fade" tag="div" class="col-12">
							<div 
								v-for="patient in filteredPatients"
								:key="patient.id"
								class="select-card with-border mt-3"
							>
								<label
									@click="Select(patient)"
								>
									<div class="d-flex align-items-center">
										<div class="ml--2 mr-4">
											<div class="float-left box box-1">
												<img 
													:src="GetAvatar(patient.avatar)" 
													class="img-cover rounded-lg"
												>
											</div>
										</div>
										<div>
											{{ patient.nome_social && patient.nome_social != 'null' ? patient.nome_social : patient.nome_paciente }}
											<small class="d-block opacity-60">
												<template v-if="patient.rh"> 
													RH: {{ patient.rh }} -
												</template>
												{{ patient.tipo_id }}: {{ patient.numero_id }}
											</small>
										</div>
									</div>
									<i class="far fa-chevron-right"></i>
								</label>
							</div>
						</transition-group>
				
					</div>
				</div>		
				<div class="col-12 col-md-8 text-center py-5">
					<div class="d-flex justify-content-between">
						<button 
							class="btn btn-light px-md-5"
							@click="Previous"
						>
							voltar
						</button>
					</div>
				</div>
			</div>
		</div>
		<div 
			class="modal fade" 
			id="patient-modal"
			data-backdrop="static"
		>
		  	<div class="modal-dialog modal-xl">
			    <div class="modal-content">
					<PatientForm
						ref="patientForm"
						label="Agendamento"
						:base_name="base_name"
						:form_type="form_type"
						:patient="selected.patient"
						:edit="true"
						@exit="New"
					>
						<template v-slot:actions="{ Save }">
							<button 
								class="btn btn-light px-md-5"
								data-dismiss="modal"
							>
								fechar
							</button>
							<button 
								class="btn btn-primary px-md-5"
								@click="Save"
							>
								salvar <i class="far fa-save ml-4"></i>
							</button>
						</template>
					</PatientForm>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	const _ 			= require('lodash')

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Patient',
		props: 	{
			label: 			{
				type: 			String,
				default: 		''
			},
			patient: 		{
				type: 			Object,
				default: 		null
			},
		},
		data () {
			return {
				search: 		'',
				patients: 		[],
				selected: 		{
					patient: 		null
				},
				form_type:		'standard',
				base_name:		null,
			}
		},
		watch: {
			patient: {
				handler(val)
				{
					if(val && !_.isEmpty(val))
					{
						this.search 				= val.nome_paciente.toLowerCase()
						this.selected.patient 		= val
					}
				},
				immediate: true
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			filteredPatients() {
				const patients 	= this.patients.filter(patient => {

					let allowed 	= false
					let search 		= helpers.RemoveAcento(this.search).toLowerCase()

					if(patient.nome_social) {
						allowed 		= helpers.RemoveAcento(patient.nome_social).toLowerCase().includes(search)
					}

					if(!allowed && patient.nome_paciente) {
						allowed 		= helpers.RemoveAcento(patient.nome_paciente).toLowerCase().includes(search)
					}

					if(!allowed && patient.rh) {
						allowed 		= helpers.RemoveAcento(patient.rh).toLowerCase().includes(search)
					}

					if(!allowed && patient.cpf) {
						allowed = helpers.SanitizedDocument(patient.cpf).includes(helpers.SanitizedDocument(search))
					}

					if(!allowed && patient.sus) {
						allowed 		= helpers.RemoveAcento(patient.sus).toLowerCase().includes(search)
					}

					if(!allowed && patient.rg) {
						let rgWithUf = `${patient.rg}${patient.rg_uf ? ' ' + patient.rg_uf : ''}`;
						let rgWithUfNoSpace = `${patient.rg}${patient.rg_uf ? patient.rg_uf : ''}`;

						let searchWithoutSpaces = search.replace(/\s/g, '').toLowerCase();

						allowed = helpers.RemoveAcento(rgWithUf).toLowerCase().includes(searchWithoutSpaces) ||
								helpers.RemoveAcento(rgWithUfNoSpace).toLowerCase().includes(searchWithoutSpaces);
					}

					if(!allowed && patient.telefone) {
						let telefone

		                try {
		                    telefone = JSON.parse(patient.telefone.replace(/\\/g,''))

		                } catch(err) {
							telefone = []
		                }

						if(Array.isArray(telefone) && telefone.length > 0) {
							telefone.forEach( tel => {
								if(tel && !allowed && tel.replace(/\D/g, '').indexOf(search) > -1) {
									allowed 	= true

									return;
								}
							})
						}
					}

					return allowed
				})

				return this.search ? (patients.length >= 10 ? patients.slice(0, 10) : patients) : []
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetPatientsForScheduling()
			{
				this.StartLoading()

				return window.api.call('post', '/get-patients-for-scheduling',{
						account_id: 	this.requester_selected_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.patients 		= _.sortBy(data.patients, ['nome_paciente'])

						}else{

							Swal.fire({
								icon: 	'error',
								title: 	'Opss...',
								text: 	'Houve um erro ao obter os pacientes, contate o suporte.'
							})
						}
					})
					.finally(() => {

						this.FinishLoading()

					})
			},
			GetAvatar(path)
			{
				return path ? path : '/img/profile.png'
			},
			NewPatientModal() {
				window.jQuery('#patient-modal').modal('show')

				this.form_type = 'standard'

				this.selected.patient = null

				this.$refs.patientForm.ClearForm()
			},
			New(patient)
			{
				this.patients.push(patient)

				this.search 			= patient.nome_paciente.toLowerCase()

				window.jQuery('#patient-modal').modal('hide')

				window.jQuery('body').removeClass('modal-open')
				
				this.Select(patient)
			},
			Select(patient)
			{
				this.selected.patient 	= patient

				const patient_phones	= patient.telefone ? JSON.parse(patient.telefone) : []


				if(patient_phones.length > 0) {
					if(patient_phones[0] != null) {
						this.$emit('next', this.selected.patient)
	
						return
					}
				}

				this.selected.patient.telefone_1	= null

				Swal.fire({
					icon: 		'warning',
					title: 		'Preencha o telefone do paciente.',
					toast:		true,
					position:	'top'
				})

				this.form_type = 'update'

				window.jQuery('#patient-modal').modal('show')
			},
			Previous()
			{
				this.$emit('previous')
			}
		},
		mounted()
		{
			this.GetPatientsForScheduling()

			this.$nextTick(() => {
				this.$refs.patient_search.focus()
			})
		}
	}
</script>

<style lang="css" scoped>
</style>
<template>
	<div
		id="active-sessions"
		class="modal fade"
		tabindex="-1"
		aria-labelledby="active-sessions"
		aria-hidden="true"
	>
		<div class="modal-dialog">
			<div class="modal-content p-4">
				<div class="modal-header position-relative">
					<h3 class="text-center pt-3 mb-0">Sessões ativas</h3>
					<div class="position-absolute ps-r--3 ps-t--3">
						<button
							id="close-modal"
							class="btn btn-light btn-sm m-2"
							@click="Close()"
						>
							<i class="fal fa-times"></i>
						</button>
					</div>
				</div>
				<div class="modal-body py-4">
					<div class="col-12 d-flex flex-column align-items-center">
						<p>
							Há sessões ativas em outros dispositivos/navegadores. Se você não reconhece alguma sessão, por favor, encerre-a.
						</p>

						<SessionsList
							ref="sessions_list"
							:auto_request="false"
							:sessions_props="sessions"
							@update-sessions="sessions = $event"
						/>
						<div class="col-12 px-0 d-flex justify-content-between align-items-center mt-4">
							<button
								class="btn btn-outline-danger disconnect-button"
								@click="DisconnectAllSessions()"
							>
								Encerrar todas as sessões
							</button>
							<button
								class="btn btn-primary keep-button"
								@click="Close()"
							>
								ignorar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Swal from 'sweetalert2'
import SessionsList from '@/components/shared/SessionsList'

export default {
	name: 'ActiveSessionsModal',
	components: {
		SessionsList
	},
	data() {
		return {
			sessions: []
		}
	},
	computed: {
		...mapGetters('auth', [
			'session_key'
		])
	},
	watch: {
		session_key: {
			handler(val) {
				if(val) {
					this.GetActiveSessions()
				}
			}
		},
		sessions: {
			handler(val) {
				if(!val.length) {
					this.Close()
				}
			}
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		GetActiveSessions() {
			this.StartLoading()

			return window.api.call('get', '/get-active-sessions')
				.then(({data}) => {
					this.sessions = this.$refs.sessions_list.ParseSessions(data.sessions)

					if(this.sessions.length) {
						this.Open()
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		DisconnectAllSessions() {
			Swal.fire({
				title: 'Encerrar Sessões!',
				html: 'Realmente deseja encerrar todas as outras sessões?<br><b>(Não afetará a sessão atual)</b>',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sim',
				cancelButtonText: 'Não',
				reverseButtons: true,
			})
				.then(async (result) => {
					if(result.isConfirmed) {
						this.$refs.sessions_list.DisconnectAll()

						this.Close()
					}
				})
		},
		Open() {
			window.jQuery('#active-sessions').modal('show')
		},
		Close() {
			window.jQuery('#active-sessions').modal('hide')

			this.$emit('close')
		},
	},
	mounted() {

	}
}
</script>

<style lagn="css" scoped>
::v-deep(.sessions-container) {
	gap: 10px;
	max-height: 600px;
	overflow: auto;
}

::v-deep(.session-card) {
	border-radius: 1rem;
	min-width: 300px;
}

::v-deep(.disconnect-button),
.keep-button {
	line-height: 1;
	border-radius: .5rem;
	padding: .5rem !important;
	font-size: 1.2rem;
}

.btn-outline-danger {
	color: #ff58696b;
	border-color: #ff58696b;
}

.btn-outline-danger:hover {
	color: var(--light);
	background-color: #e04d5b6b;
	border-color: #e04d5b6b;
}

@media screen and (max-width: 768px) {
	::v-deep(.session-card) {
		max-width: 500px;
	}
}
</style>
<template>
	<div v-if="patient && intake_form"
		class="col-12">
		<div class="border bg-light rounded-lg w-100">
			<div class="p-4">
				<div class="row">
					<div class="col-12" v-if="!print_component">
						<div v-if="patient" class="row mb-3">
							<div class="col-3 col-md-auto mb-4 d-flex flex-column align-items-center">
								<img 
									:src="avatar"
									class="rounded-lg border border-secondary patient-avatar"
								>
							</div>
							<div class="col">
								<div class="row">
									<h4 class="m-0 mb-3 font-20">{{ patient.name }}</h4>
								</div>
								<div class="row">
									<p class="mb-0">
										RG: <span :class="patient.rg ? 'font-weight-bold' : 'unknown'">{{ patient.rg ? patient.rg.toUpperCase() : 'DESCONHECIDO' }}</span>
										<span v-if="patient.rg_uf != 'DESCONHECIDO'" :class="patient.rg_uf ? 'font-weight-bold' : 'unknown'">{{ patient.rg_uf ? patient.rg_uf.toUpperCase() : '' }}</span>
									</p>
									<p class="mb-0">
										CPF: <span :class="patient.cpf ? 'font-weight-bold' : 'unknown'">{{ patient.cpf ? patient.cpf.toUpperCase() : 'DESCONHECIDO' }}</span>
									</p>
									<p class="mb-0">
										SUS: <span :class="patient.sus ? 'font-weight-bold' : 'unknown'">{{ patient.sus ? patient.sus.toUpperCase() : 'DESCONHECIDO' }}</span>
									</p>
									<p class="mb-0">
										RH: <span :class="patient.rh ? 'font-weight-bold' : 'unknown'">{{ patient.rh ? patient.rh.toUpperCase() : 'DESCONHECIDO' }}</span>
									</p>
								</div>
								<div class="row my-2">
									<div class="px-0">
										DATA DE NASCIMENTO: <span :class="patient_birthdate ? 'font-weight-bold' : 'unknown'">{{ patient_birthdate }}</span>
										<span v-if="patient.date_of_birth" :class="patient_age ? 'font-weight-bold' : 'unknown'"> ({{ patient_age }})</span>
									</div>
									<div class="px-0">
										SEXO: <span :class="patient.sex ? 'font-weight-bold' : 'unknown'">{{ patient.sex ? (patient.sex === 'M' ? 'MASCULINO' : 'FEMININO') : 'DESCONHECIDO' }}</span>
									</div>
									<div v-if="patient.telefone" class="px-0">
										TELEFONE: <b> {{ patient.cellphone }} </b>
									</div>
									<div v-if="is_endoscopy_modality && patient.mother_name" 
										class="px-0">
										NOME MÃE: <span class="mb-0"><b>{{ patient.mother_name }}</b></span>
									</div>
								</div>
								<div class="row">
									<div v-if="partnership" 
										class="px-0">
										CONVÊNIO: <span class="mb-0"><b>{{ partnership.toUpperCase() }}</b></span>
									</div>
									<div
										class="px-0">
										SOLICITANTE: <span :class="indicator ? 'font-weight-bold' : 'unknown'">{{ indicator ? indicator.toUpperCase() : 'DESCONHECIDO' }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="intake_form" class="col-12">
						<div class="pb-3">
							<div class="col-12 text-center">
								<h1>FICHA DE ATENDIMENTO</h1>
							</div>
							<div class="row mt-4">
								<div class="col-12 text-dark text-center">
									<h3>MODALIDADE: {{ intake_form.modality }}</h3>
								</div>
							</div>
							<hr/>
							<template>
								<div class="my-5">
									<div class="row" v-if="intake_form.origin === ORIGINS.SCHEDULING_URGENT_APPOINTMENT">
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												DATA: <b>{{ parsed_date }}</b>
											</p>
										</div>
									</div>
									<div class="row" v-else-if="intake_form.origin === ORIGINS.FIT || scheduling.is_fit_no_show">
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												DATA: <b>{{ parsed_date }} {{ parsed_time }} [ENCAIXE]</b>
											</p>
										</div>
									</div>
									<div class="row" v-else>
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												DATA: <b>{{ attendance_string }}</b>
											</p>
										</div>
									</div>
									<div class="row">
										<div class="col-12 text-dark">
											<p class="mb-0 h4-size">
												AGENDA: <b>{{ intake_form.schedule.name.toUpperCase() }}</b>
											</p>
										</div>
									</div>
								</div>
							</template>
							<hr/>
							<div class="mt-5">
								<div class="row mb-3">
									<div 
										class="col-12 text-dark h4-size"
										v-for="(proceeding, index) in intake_form.proceedings"
										:key="index"
									>
										<div v-if="proceeding">
											<p class="mb-0"><b>({{ index + 1 }}): {{ proceeding.name }}</b></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>
		</div>
		<div v-if="intake_form?.proceedings?.length > 0 && has_instruction" class="border bg-light rounded-lg w-100 separate-cards">
			<div class="p-4">
				<div class="">
					<div class="mb-3">
						<div class="col-12 text-center mb-3">
							<h1>
								Instruções
							</h1>
						</div>
						<hr>
						<div
							class="row col-12 text-dark"
							v-for="(proceeding, index) in intake_form.proceedings"
							:key="index"
						>
							<div v-if="proceeding && proceeding.prepare" class="col-12 p-0">
								<br v-if="index !== 0">
								<p class="mb-0 h4-size">
									{{ proceeding.prepare }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="type === 'scheduling'"
			class="border bg-light rounded-lg w-100 separate-cards">
			<div class="p-4">
				<div class="form-group">
					<textarea
						v-debounce:500="DelayFillObs"
						class="form-control text-dark"
						placeholder="Observações"
						v-model="form.obs"
					/>
				</div>
			</div>
		</div>
		<div v-else-if="intake_form && intake_form.obs !== null"
			class="border bg-light rounded-lg w-100 separate-cards">
			<div class="p-4">
				<div class="row">
					<div class="col-12 text-dark">
						<p class="mb-0 h4-size">
							<b>Obs.: </b>{{ intake_form.obs }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	import moment from 'moment'
	export default {
		name: 'SchedulingCard',
		props: 	{
			type: {
				type: String,
				default: 'consult'
			},
			scheduling_base: {
				type: Object,
				default: () => {}
			},
			attendance_id: 	{
				type: [ String, Number ],
				default: null
			},
			print_component: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				ORIGINS: {
					SCHEDULING_PRE_APPOINTMENT: 2,
					SCHEDULING_URGENT_APPOINTMENT: 3,
					FIT: 4,
				},
				scheduling:				null,
				intake_form: 			null,
				patient: 				null,
				defaultAvatar: 			window.defaultAvatar,
				form:					{
					obs:				''
				},
				payment_methods_info: {
					methods: [],
					discount: null,
					total: null
				}
			}
		},
		computed: 	{
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			avatar() {
				return this.patient && this.patient.avatar ? this.patient.avatar : this.defaultAvatar
			},
			doc_id() {
				if(this.patient.doc_id.tipo) {
					return `${this.patient.doc_id.tipo.toUpperCase()} ${this.patient.doc_id.numero.toUpperCase()}`
				} else {
					return `${this.patient.doc_id}`
				}
			},
			has_instruction() {
				const vm = this

				if(vm.intake_form && vm.intake_form?.proceedings?.length) {
					return !!vm.intake_form.proceedings.filter(p => p && !!p.prepare).length
				}

				return false
			},
			attendance_time() {
				const vm = this

				return vm.intake_form.schedule.time_attendance || 0
			},
			attendance_string() {
				const vm = this

				if(vm.intake_form.origin === vm.ORIGINS.SCHEDULING_PRE_APPOINTMENT) {
					const attendance_time = moment(vm.intake_form.scheduling_date_time).subtract(vm.attendance_time, 'minutes')
					
					const time = {
						dia: moment(vm.intake_form.scheduling_date_time).format('DD/MM/YYYY'),
						hora: moment(attendance_time).format('HH:mm')
					}
	
					if(time.dia && time.hora) {
						return `${time.dia} às ${time.hora}`
					}
				}

				return ''	
			},
			parsed_date() {
				const vm = this

				if(vm.intake_form.scheduling_date_time) {
					return moment(vm.intake_form.scheduling_date_time).format('DD/MM/YYYY')
				}

				return ''
			},
			parsed_time() {
				const vm = this

				if(vm.intake_form.scheduling_date_time) {
					return moment(vm.intake_form.scheduling_date_time).format('HH:mm')
				}

				return ''
			},
			is_endoscopy_modality() {
				const ENDOSCOPY_MODALITY_ID = 27

				return this.intake_form?.proceedings?.filter(p => p.modality_id == ENDOSCOPY_MODALITY_ID).length > 0 ?? false
			},
			patient_birthdate() {
				let birthdate = 'DESCONHECIDO';

				if(!this.patient.date_of_birth) return birthdate

				birthdate = moment(this.patient.date_of_birth).format('DD/MM/YYYY')

				return birthdate
			},
			patient_age() {            		
	            if(this.patient?.date_of_birth) {
	                const dob 		= moment(this.patient.date_of_birth, 'YYYY-MM-DD')
	                const today 	= moment()

	                const years 	= today.diff(dob, 'year')
	                dob.add(years, 'years')

	                const months 	= today.diff(dob, 'months')
	                dob.add(months, 'months')
	                
	                const days 		= today.diff(dob, 'days')


	                let result 		= ''

	                if(years > 0) {
	                   result =  `${years} ANO${years > 1 ? 'S' : ''}`
	                } else if (years === 0 && months > 0) {
	                    result = `${months} MES${months > 1 ? 'ES' : ''} e ${days} DIA${days > 1 ? 'S' : ''}`
	                } else if (months === 0 && days > 0) {
	                    result = `${days} DIA${days > 1 ? 'S' : ''}`
	                } else {
	                    result = '0 DIAS'
	                }
	                
	                return result
	            } else {
	                return 'DESCONHECIDO'
	            }
			},
		},
		watch: {
			scheduling_base: {
				immediate: true,
				deep: true,
				handler(val) {
					if(val) {
						this.PopulateIntakeForm({ ...val })
					}
				}
			},
			attendance_id: {
				async handler(val) {
					if(val) {
						await this.GetScheduling()
					}
				},
				immediate: true
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			PopulateIntakeForm(scheduling) {
				const vm = this

				if(scheduling) {
					vm.scheduling = scheduling
					vm.intake_form = vm.scheduling.intake_form
					vm.patient = vm.scheduling.header.patient
					vm.partnership = vm.scheduling.header.partnership
					vm.indicator = vm.scheduling.header.indicator
					vm.payment_methods_info = vm.scheduling.payment_info

					vm.$emit('update-payment-info', vm.payment_methods_info)
				}

				// TODO: Fazer a parte de pagamento.

				// this.$emit('update-indicator', vm.scheduling_data.indicator)
			},
	        async GetScheduling() {
	            const vm = this

	            if(vm.attendance_id) {
	            	vm.StartLoading()

	                return await window.api.call('post', '/get-attendances', {
	                        account_id: vm.requester_selected_id,
							attendance_id: vm.attendance_id
	                    })
		                .then(({data}) => {
			                if(data.response) {
								vm.scheduling = null

								if(data.scheduling) {
									vm.PopulateIntakeForm(data.scheduling)

									this.$emit('update-attendance', data.scheduling)
								}
			                } else {
			                    Swal.fire({
			                        icon:   'error',
			                        text:   'Houve um erro ao obter, contate o suporte.',
			                        title:  'Opss...'
			                    })
			                }
		                })
		                .finally(() => {
		                	vm.FinishLoading()
		                })
	            } else {

	                Swal.fire({
	                    icon: 'error',
	                    title: 'Dados não informados',
	                    text: 'São necessários os dados para a impressão do comprovante'
	                })
	            }
	        },
			DelayFillObs() {
				const vm = this
				
				vm.scheduling.obs = vm.form.obs

				vm.$emit('update-obs', vm.scheduling.obs)
			}
		}
	}
</script>

<style lang="css" scoped>
	.row {
		gap: 0px 10px;
	}

	.separate-cards {
		margin-top: 4rem;
	}

	.h4-size {
		font-size: 1.4rem;
	}

	.tab-1 {
		tab-size: 2;
	}

	textarea {
		resize: none
	}

	.patient-avatar {
		height: 80px;
	}

	@media (min-width: 768px) {
		.patient-avatar {
			height: 160px;
		}
	}
</style>
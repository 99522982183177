<template>
	<div class="col-12 px-0 d-flex align-items-center flex-wrap sessions-container">
		<div v-for="(session, index) in sessions"
			:key="index"
			class="card card-body p-3 session-card"
			:class="GetOrder(session, index)"
		>
			<div class="col-12 px-0 d-flex justify-content-between position-relative">
				<div class="col-12 px-0">
					<div class="row m-0 font-18" v-tippy="{ arrow: true, content: 'Organização', followCursor: true }">
						<span class="col-1 px-0 d-flex justify-content-center align-items-center">
							<i class="far fa-hospital font-20 mx-2"></i>
						</span>
						<span class="col px-0">
							{{ session.access_info?.organization_name?.toUpperCase() ?? '(Não informado)' }}
						</span>
					</div>
					<div class="row m-0" v-tippy="{ arrow: true, content: 'Localização', followCursor: true }">
						<span class="col-1 px-0 d-flex justify-content-center align-items-center">
							<i class="fas fa-location-dot font-20 mx-2"></i>
						</span>
						<span class="col px-0">
							{{ session.access_info.location }}
						</span>
					</div>
					<div class="row m-0" v-tippy="{ arrow: true, content: 'Data/hora', followCursor: true }">
						<span class="col-1 px-0 d-flex justify-content-center align-items-center">
							<i class="far fa-clock font-16 mx-2"></i>
						</span>
						<span class="col px-0">
							{{ session.date }}, {{ session.time }}
						</span>
					</div>
					<div class="row m-0" v-tippy="{ arrow: true, content: 'Dispositivo - Navegador - Sistema Operacional', followCursor: true }">
						<span class="col-1 px-0 d-flex justify-content-center align-items-center">
							<i :class="`far fa-${session.access_info.type_class} mx-2`"></i>
						</span>
						<span class="col px-0">
							{{ session.access_info.browser }}
							-
							{{ session.access_info.os }}
						</span>
					</div>
					<div class="row m-0" v-tippy="{ arrow: true, content: 'Endereço IP', followCursor: true }">
						<span class="col-1 px-0 d-flex justify-content-center align-items-center">
							<strong class="mx-2">IP</strong>
						</span>
						<span class="col px-0">
							{{ session.access_info.ip_address }}
						</span>
					</div>
				</div>
				<div class="position-absolute ps-r-0 ps-t-0">
					<p v-if="session.id === tab_id" class="m-0">
						<span class="badge badge-primary">Sessão atual</span>
					</p>
					<button 
						v-else
						class="btn btn-outline-danger disconnect-button"
						@click="DisconnectSession(session)"
					>
						<i class="far fa-sign-out mx-2"></i>
					</button>
				</div>
			</div>
			<small class="session-footer"
				@click="CopySessionKeyToClipboard(session.id)"
			>Sessão ID: {{ session.id }}</small>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from 'moment'

export default {
	name: 'SessionsList',
	props: {
		sessions_props: {
			type: Array,
			required: false
		},
		auto_request: {
			type: Boolean,
			default: true
		},
		light: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			sessions: []
		}
	},
	computed: {
		...mapGetters('auth', [
			'account', 'tab_id'
		]),
		sessions_ids() {
			return this.sessions.map(session => session.id)
		}
	},
	watch: {
		sessions_props: {
			handler(val) {
				this.sessions = _.orderBy(val, ['date', 'time'], ['asc', 'asc'])
			}
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		ParseSessions(sessions) {
			return sessions.map(session => {
				let type = 'PC'

				if(session.access_info?.is_mobile) {
					type = 'Celular'
				} else if(session.access_info?.is_tablet) {
					type = 'Tablet'
				}

				let location = ''

				if(session.access_info?.location) {
					const { city, region_code } = session.access_info?.location;
					location = [city, region_code].filter(Boolean).join(' - ');
				}

				return {
					id: session.id,
					date: moment(session.created_at).format('DD/MM/YYYY'),
					time: moment(session.created_at).format('HH:mm:ss'),
					access_info: {
						ip_address: session.access_info?.ip_address,
						organization_name: session.access_info?.organization_name,
						type,
						type_class: session.access_info?.is_mobile ? 'mobile' : session.access_info?.is_tablet ? 'tablet' : 'desktop',
						browser: session.access_info?.browser,
						os: session.access_info?.os,
						location
					}
				}
			})
		},
		GetSessions() {
			this.StartLoading()

			return window.api.call('post', '/get-sessions')
				.then(({data}) => {
					if(data.response) {
						this.sessions = this.ParseSessions(data.sessions)
					}
				})
				.finally(() => {
					this.FinishLoading()
				})
		},
		GetOrder(session, index) {
			let order = 0

			if(this.light || session.id !== this.tab_id) {
				order = index + 1
			}
			
			return'order-' + order
		},
		DisconnectSession(session) {
			let text = `
				Realmente deseja desconectar a seguinte sessão?<br>
				<br>
				<i class="far fa-hospital font-20 mr-3"></i><b>${ session.access_info.organization_name ?? '(Não informado)' }</b><br>
				<i class="fas fa-location-dot font-20 mr-3"></i><b>${ session.access_info.location }</b><br>
				<i class="far fa-clock font-16 mr-3"></i><b>${ session.date }, ${ session.time }</b><br>
				<i class="far fa-${session.access_info.type_class} mr-3"></i><b>${ session.access_info.browser } - ${ session.access_info.os }</b><br>
				<span class="mr-3">IP</span><b>${ session.access_info.ip_address }</b><br>
			`

			Swal.fire({
				title: 'Desconectar Sessão',
				html: text,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sim',
				cancelButtonText: 'Não',
				reverseButtons: true,
			})
				.then((result) => {
					if(result.isConfirmed) {
						this.Disconnect([session.id])
					}
				})
		},
		DisconnectAll() {
			this.Disconnect(this.sessions_ids)
		},
		async Disconnect(sessions_ids) {
			return window.Session.Disconnect(sessions_ids)
				.then(({data}) => {
					if(data.response) {
						this.sessions = this.sessions.filter(s => sessions_ids.indexOf(s.id) === -1)

						this.$emit('update-sessions', this.sessions)
					}
				})
		},
		CopySessionKeyToClipboard(session_id) {
			const session_key = `user:${this.account.id}:session:${session_id}`

			navigator.clipboard.writeText(session_key)
		}
	},
	mounted() {
		if(this.auto_request) {
			this.GetSessions()
		}
	}
}
</script>

<style lang="css" scoped>
.sessions-container {
	gap: 10px;
	max-height: 600px;
	overflow: auto;
}

.disconnect-button {
	border-radius: .5rem;
	padding: .5rem !important;
}

.btn-outline-danger {
	color: #ff58696b;
	border-color: #ff58696b;
}

.btn-outline-danger:hover {
	color: var(--light);
	background-color: #e04d5b6b;
	border-color: #e04d5b6b;
}

.session-footer {
	font-size: 12px;
	color: var(--color-font);
	opacity: .35;
}

@media screen and (max-width: 768px) {
	.session-card {
		max-width: 500px;
	}
}
</style>
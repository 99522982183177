<template>
	<div>
		<div class="container">
			<div class="row align-items-center vh-md-80 justify-content-between flex-column">
				<div class="col-12">
					<div class="d-block mx-auto mb-4 pb-md-5 text-md-center max-500">
						<span>{{ label }}</span>
						<h4 class="font-24 font-md-32 m-0">Defina o convênio e os procedimentos do agendamento</h4>
					</div>
				</div>
				<div class="col-12 col-md">
					<div class="row justify-content-between">
						<div class="col-12 mb-3">					
							<div class="row align-items-center">
								<div class="col form-group position-relative">
									<select 
										class="form-control"
										v-model="selected.partnership_id"
										@change="GetProceedings"
										@click="select_focused = !select_focused"
										@blur="select_focused = false"
									>	
										<option :value="null">Selecione um convênio...</option>
										<option
											v-for="(partnership, index) in partnerships"
											:key="index"
											:value="partnership.id"
										>
											{{ partnership.nome }}
										</option>
									</select>
									<i class="font-18 position-absolute ps-r-4 mr-3 ps-v-center fas "
									  :class="select_focused ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
								</div>
							</div>
						</div>
					</div>
					<transition name="fade" mode="out-in">
						<div class="row justify-content-between" v-if="selected.partnership_id">
							<div class="col-12 col-md-6">
								<div class="form-group position-relative mb-0">
									<input 
										type="search"
										autofocus
										class="form-control border-no pl-5" 
										placeholder="Buscar..." 
										v-model="search"
									>
									<i class="far fa-search font-14 position-absolute ps-l-1 ml-3 ps-v-center"></i>
								</div>
								<div class="card bg-light border-no overflow-hidden">
									<div class="card-body p-3 p-md-5 scroll-this">
										<template v-if="proceedings_loading">
											<div class="col d-flex justify-content-center">
												<div class="spinner-border text-theme" role="status"></div>
											</div>
										</template>
										<template v-else>
											<div v-if="filteredProceedings.length">
												<div
													v-for="(proceeding, index) in filteredProceedings"
													:key="index"
													class="select-card with-border mt-3"
													@click="SelectProceeding(proceeding)"
												>
													<label>
														{{ proceeding.nome }}
														<i class="far fa-plus"></i>
													</label>
												</div>
											</div>
											<div v-else class="text-center">
												Não há procedimentos no convênio selecionado.
											</div>
										</template>
									</div>
								</div>
							</div>
							<transition name="fade" mode="out-in">
								<div 
									class="col-12 col-md-5 pt-md-5"
									v-if="selectedProceedingsInfo.count > 0"
								>
									<div class="my-4">
										<div 
											v-for="(proceeding, index) in selected.proceedings"
											:key="index"
											class="select-card with-border mt-3"
											@click="RemoveProceeding(proceeding)"
										>
											<label>
												{{ proceeding.nome }}
												<i class="far fa-times"></i>
											</label>
										</div>
									</div>
									<hr class="opacity-50">
									<div class="row">
										<div class="col-7">
											<span class="opacity-75">
												Procedimentos
											</span>
										</div>
										<div class="col-5 text-dark">
											{{ selectedProceedingsInfo.count }}
										</div>
									</div>
									<div v-if="!is_vacancy" class="row">
										<span class="opacity-75 col-7">
											Tempo estimado
										</span>
										<div class="col-5 text-dark">
											{{ selectedProceedingsInfo.time }} minuto(s)
										</div>
									</div>
									<div v-else class="row">
										<span class="opacity-75 col-7">
											Vaga(s) utilizada(s)
										</span>
										<div class="col-5 text-dark">
											{{ selectedProceedingsInfo.time / 5 }}
										</div>
									</div>
								</div>
							</transition>
						</div>
					</transition>
				</div>	
				<div class="col-12 col-md-6 text-center py-5">
					<div class="d-flex justify-content-between">
						<button 
							class="btn btn-light px-md-5"
							@click="Previous"
						>
							voltar
						</button>
						<button 
							class="btn btn-primary px-md-5"
							v-if="selectedProceedingsInfo.count > 0"
							@click="Continue"
						>
							continuar <i class="far fa-arrow-right ml-4"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const _ 			= window._
	
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'ProceedingAndPartnership',
		props: 	{
			label: 			{
				type: 			String,
				default: 		''
			},
			modality_id: 	{
				type: 			[String, Number],
				default: 		null
			},
			partnership:	{
				type: 			Object,
				default: 		null
			},
			proceedings:	{
				type: 			Array,
				default: 		null
			}
		},
		data () {
			return {
				search: 					'',
				partnerships: 				[],
				loaded_proceedings: 		[],
				selected: 					{
					partnership_id: 			null,
					proceedings: 				[],
					schedules: 					[]
				},
				select_focused:				false,
				is_vacancy:					false,
				proceedings_loading: 		false
			}
		},
		watch: {
			modality_id: {
				async handler(val) {
					if(val) {
						await this.GetPartnerships()

						if(this.selected.partnership_id) {
							await this.GetProceedings()
						}
					}				
				},
				immediate: true
			},
			partnership: {
				handler(val) {
					this.selected.partnership_id = val ? parseInt(val.id) : null
				},
				immediate: true
			},
			proceedings: {
				handler(val) {
					this.selected.proceedings = val
				},
				immediate: true
			},
			'selected.partnership_id': {
				async handler(val, oldVal) {
					if(oldVal == null) return
					
					if(val != oldVal) {
						this.selected.proceedings = []
					}
				}
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			selectedProceedingsInfo()
			{
				let time = 0 

				this.selected.proceedings.map( p => {
					time 	= parseInt(p.duracao) + time
				})

				return {
					time: 	time,
					count: 	this.selected.proceedings.length
				}
			},
			filteredProceedings() {
				return this.loaded_proceedings.filter(proceeding => {
					let have_schedule 			= true
					this.selected.schedules 	= []

					if(this.selected.proceedings.length > 0) {
						have_schedule 			= false

						let arr 				= []

						_.forEach( _.map(this.selected.proceedings, 'schedules'), schedules => {
							arr 					= _.concat(arr,schedules)
						})

						this.selected.schedules	= _.union( _.compact( _.map(arr, (item) => {
							if(_.filter(arr, { id: item.id }).length == this.selected.proceedings.length) {
								return item.id;
							}

					      	return false;
						})))

						this.selected.schedules = _.map(this.selected.schedules, (schedule) => {
							return arr.find( x => x.id == schedule)
						})

						_.forEach(this.selected.schedules, value => {
							if(!have_schedule) {
								have_schedule 		= _.filter(proceeding.schedules,{ id: value.id }).length > 0
							}
						})
					}

					let find 			= true

					if(this.search) {
						find 				= helpers.RemoveAcento(proceeding.nome).toLowerCase().includes(helpers.RemoveAcento(this.search).toLowerCase())
					}

					return find && have_schedule

				})
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			async GetPartnerships() {
				this.StartLoading()

				return window.api.call('post', '/get-partnerships', {
					organization_id: this.requester_selected_id,
					light: true
				})
					.then(({data}) => {
						if(data.response) {
							this.partnerships = data.partnerships

							if(this.partnerships.length === 1) {
								this.selected.partnership_id = this.partnerships[0].id
							}
						}
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			async GetProceedings() {
				this.proceedings_loading = true

				return window.api.call('post', '/get-proceedings', {
					organization_id: this.requester_selected_id,
					modality_id: this.modality_id,
					type: 3,
					partnership_id: this.selected.partnership_id
				})
					.then(({data}) => {
						if(data.response) {
							this.loaded_proceedings = data.proceedings
							this.is_vacancy = data.is_vacancy
						}
					})
					.finally(() => {
						this.proceedings_loading = false
					})
			},
			SelectProceeding(proceeding) {
				this.selected.proceedings.push(proceeding)
			},
			RemoveProceeding(target) {
				let i = false

				this.selected.proceedings = this.selected.proceedings.filter(proceeding => {
					if(proceeding.id == target.id && !i) {
						i 	= true

						return false
					} else {
						return true
					}
				})
			},
			Continue() {
				if(this.selected.proceedings.length > 0) {
					if(this.selected.schedules.length > 0) {
						this.selected.partnership 	= this.partnerships.find(p => {
							return p.id === parseInt(this.selected.partnership_id)
						})

						this.$emit('next', this.selected)
					} else {
						Swal.fire({
							icon: 	'error',
							title: 	'Eita!',
							text: 	'Não há agendas disponiveis para esta combinação de procedimentos.'
						})
					}
				} else {
					Swal.fire({
						icon: 	'error',
						title: 	'Opss...',
						text: 	'Você precisa selecionar algum procedimento para continuar.'
					})
				}
			},
			Previous() {
				this.$emit('previous')
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
<template>
	<div>
		<template v-if="selected_vision == 'day'">
			<div class="row justify-content-between align-items-end mt-5">
				<div class="col-12 max-600">
					<div class="row">
						<div class="col-md">
							<div class="form-group m-md-0 position-relative">
								<input 
									type="search" 
									class="form-control pl-5" 
									placeholder="Buscar..."
									v-model="search"
								>
								<i 
									class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"
								/>
							</div>
						</div>
						<div class="col-md-auto">
							<div class="form-group m-md-0 position-relative">
								<input 
									type="date" 
									class="form-control pl-5" 
									placeholder="data"
									v-model="date"
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<hr class="my-4">
		<div class="row justify-content-between align-items-center mb-4">
			<div class="col-md-auto">
				<div class="d-flex align-items-end justify-content-between">
					<button 
						class="btn btn-light btn-sm"
						@click="PreviousDate"
					>
						<i class="far fa-chevron-left"></i>
					</button>
					<div class="mx-2 mx-md-4">
						<small>{{ vision_name }}</small>
						<h4 class="font-18 font-md-24 mb-0 mt--2">
							{{ date_indicator }}
						</h4>
					</div>
					<button 
						class="btn btn-light btn-sm"
						@click="NextDate"
					>
						<i class="far fa-chevron-right"></i>
					</button>
					<button 
						class="btn btn-light-primary btn-sm ml-2"
						@click="SetToday"
					>
						hoje
					</button>
					<button 
						class="btn btn-light-success btn-sm ml-2"
						@click="( $emit('refresh', selected_vision) )"
					>
						<i class="far fa-sync"></i>
					</button>
				</div>
			</div>
			<div class="col-md-auto text-center">
				<div class="btn-group-checkbox d-inline-block mt-4 mb-3 my-md-0">
					<label for="ano">
						<input 
							type="radio" 
							value="year" 
							id="ano" 
							v-model="selected_vision"
						>
						<div class="btn btn-light">
							ano
						</div>
					</label>
					<label for="mes">
						<input 
							type="radio" 
							value="month" 
							id="mes" 
							v-model="selected_vision"
						>
						<div class="btn btn-light">
							mês
						</div>
					</label>
					<label for="dia">
						<input 
							type="radio" 
							value="day"
							id="dia" 
							v-model="selected_vision"
						>
						<div class="btn btn-light">
							dia
						</div>
					</label>
				</div>
			</div>
			<div class="col-md-auto">
				<slot name="form"></slot>
			</div>
		</div>
			
		<div class="col-12 px-0 my-2">
			<slot name="statistics"></slot>
		</div>
		
		<div class="card-body px-3 py-2">
			<transition name="fade" mode="out-in">
				<div v-if="selected_vision == 'year'" key="1">
					<div class="card border-no bg-light">
						<div class="card-body px-3 py-2">
							<div class="row justify-content-between mx--2">
								<div 
									v-for="(month, index) in months"
									:key="index"
									class="col-6 col-md-3 px-2 py-2 data-container-month"
								>
									<div class="bg-white border-hover rounded-theme-md p-3 h-100 w-100">
										<div class="d-flex flex-column align-items-cente mx--3 h-100">
											<div class="px-3 mb-3">
												<div class="d-flex justify-content-between align-items-center position-relative">
													<button 
														class="btn btn-sm btn-light p-2 px-3"
														@click="GoToMonth(month)"
													>
														{{ month.initials }}
													</button>
													<h6 v-if="month.heatmap"
														class="flex-grow-1 m-0 font-20 text-center"
														:class="`${GetColorDay(month)}`">
														{{ GetHeatFullBusy(month.index) }} 
													</h6>
													<div class="form-check mr--3" v-if="!DateHasPassed(month)">
														<input 
															type="checkbox" 
															:id="`month-${index}`"
															:value="month.value"
															v-model="selected_months"
														>
														<label :for="`month-${index}`"></label>
													</div>
												</div>
											</div>
											<template v-if="month.heatmap">
												<div class="col px-0 d-flex flex-column align-items-start font-16" v-if="month.heatmap">
													<div class="col px-3 font-14"
														:class="`${GetDisabledColor(month)}`">
														PROCEDIMENTO:
															<span class="font-weight-bold" :class="{ 'text-busy': GetProceedingsCount(month.index) > 0 && !DateHasPassed(month) }">
																{{ GetProceedingsCount(month.index) }}
															</span>
													</div>
													<div v-if="is_vacancy" class="col px-3 font-14"
														:class="`${GetDisabledColor(month)}`">
														VAGA: 
															<span class="font-weight-bold" :class="{ 'text-busy': GetHeatBusy(month.index) && !DateHasPassed(month) }">
																{{ GetHeatBusy(month.index) / 5 }}
															</span>
															/ 
															<span>
																{{ GetAttendances(month.index) }}
															</span>
													</div>
													<div v-else class="col px-3 font-14"
														:class="`${GetDisabledColor(month)}`">
														TEMPO:
															<span class="font-weight-bold" :class="{ 'text-busy': GetHeatBusy(month.index) && !DateHasPassed(month) }">
																{{ ParseToTime(GetHeatBusy(month.index)) }}
															</span>
															/
															<span>
																{{ ParseToTime(GetHeatTotal(month.index)) }}
															</span>
													</div>
													<div class="col px-3 font-14"
														:class="{ 'text-secondary opacity-50': DateHasPassed(month) }">
														BLOQUEIO: 
															<span :class="{ 'text-warning' : GetHeatBlocked(month.index) && !DateHasPassed(month) }">
																{{ GetTotalBasedPercentage(GetHeatBlocked(month.index), month.index) }}
															</span>
													</div>
												</div>
											</template>
											<template v-else>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div 
					v-if="selected_vision == 'month'" 
					key="2"
				>
					<div class="card border-no bg-light scroll-x">
						<div class="card-body px-3 py-2">
							<div class="d-flex justify-content-between mx--2 mt-2">
								<div 
									v-for="(weekday, index) in weekdays"
									:key="index"
									class="row-7 px-2 py-2 header-day"
								>
									<div class="col">
										<span class="font-16 font-normal text-dark">{{ weekday.initials }}.</span>
									</div>					
								</div>
							</div>
							<div 
								v-for="(week, j) in weeks"
								:key="j"
								class="d-flex justify-content-between mx--2"
							>
								<div 
									v-for="(day, i) in week"
									:key="i"
									class="row-7 px-2 py-2 data-container-day"
								>
									<div 
										class="bg-white border-hover rounded-theme-md p-3 w-100 h-100"
									>
										<div class="d-flex flex-wrap mx--3 h-100">							
											<div class="col px-3 flex-grow-1">
												<div class="d-flex justify-content-between align-items-center position-relative">
													<button 
														class="btn btn-sm btn-light p-2 px-3 font-14 font-md-16"
														@click="GoToDay(day)"
													>
														{{ day.dayOfMonth }}.
													</button>
													<h6 v-if="GetHeat(day.date)"
														class="flex-grow-1 m-0 font-20 text-center"
														:class="`${GetColorDay(day)}`">
														{{ GetHeatFullBusy(day.date) }} 
													</h6>
													<div class="form-check mr--3" v-if="day.isCurrentMonth && !DateHasPassed(day)">
														<input 
															type="checkbox" 
															:id="`day-${day.date}`"
															:value="day.date"
															v-model="selected_days"
														>
														<label :for="`day-${day.date}`"></label>
													</div>
												</div>
											</div>
											<template v-if="day.isCurrentMonth">
												<div class="col px-0 d-flex flex-column align-items-start font-16" v-if="GetHeat(day.date)">
													<div class="col px-3 font-14"
														:class="`${GetDisabledColor(day)}`">
														PROCEDIMENTO:
															<span class="font-weight-bold" :class="{ 'text-busy': GetProceedingsCount(day.date) > 0 && !DateHasPassed(day) }">
																{{ GetProceedingsCount(day.date) }}
															</span>
													</div>
													<div v-if="is_vacancy" class="col px-3 font-14"
														:class="`${GetDisabledColor(day)}`">
														VAGA:
															<span class="font-weight-bold" :class="{ 'text-busy': GetHeatBusy(day.date) && !DateHasPassed(day) }">
																{{ GetHeatBusy(day.date) / 5 }}
															</span>
															/ 
															<span>
																{{ GetAttendances(day.date) }}
															</span>
													</div>
													<div v-else class="col px-3 font-14"
														:class="`${GetDisabledColor(day)}`">
														TEMPO:
															<span class="font-weight-bold" :class="{ 'text-busy': GetHeatBusy(day.date) && !DateHasPassed(day) }">
																{{ ParseToTime(GetHeatBusy(day.date, is_vacancy)) }}
															</span>
															/
															<span>
																{{ ParseToTime(GetHeatTotal(day.date)) }}
															</span>
													</div>
													<div class="col px-3 font-14"
														:class="{ 'text-secondary opacity-50': DateHasPassed(day) }">
														BLOQUEIO: 
															<span
																class="font-weight-bold"
																:class="{ 'text-warning' : GetHeatBlocked(day.date) && !DateHasPassed(day) }">
																{{ GetTotalBasedPercentage(GetHeatBlocked(day.date), day.date) }}
															</span>
													</div>
												</div>
											</template>
											<template v-else>
												<div style="height: 110px;" />
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div 
					v-if="selected_vision == 'day'"  
					key="3" 
					class="overflow-calendar-table"
				>
					<DataTables
						:headers="headers"
						v-model="parsed_rows"
						:checkbox="!DateHasPassed(selected)"
						:row_color="true"
						:search="search"
						checkbox_value="id"
						@checkeds="GetCheckeds"
						max_height="600px"
						:showTotal="false"
						:header_style="{
							'position': 'sticky',
							'top': '0px',
							'background-color': 'var(--dark-theme)'
						}"
					>
						<template v-slot:body="{ item }">
							<td scope="col" class="text-muted">
								{{ item.hora.toString().substring(0,5) }}
							</td>
							<td scope="col" class="text-muted">
								<b>{{ item.nome_paciente }}</b>
							</td>
							<td scope="col" class="text-muted">
								{{ item.doc }}
							</td>
							<td scope="col" class="text-muted">
								{{ item.nome_procedimento }}
							</td>
							<td scope="col" class="text-muted">
								{{ item.agenda }}
							</td>
							<td scope="col" class="text-muted">
								<b>{{ item.status_descricao ? item.status_descricao.toUpperCase() : '' }}</b>
							</td>
						</template>
					</DataTables>
				</div>
			</transition>
		</div>
		
		<div class="row justify-content-between my-4">
			<div class="col-md-auto d-flex flex-wrap justify-content-between gap-1">
				<button 
					class="col-5 col-md-auto btn btn-light-warning btn-sm px-3 mr-md-1"
					@click="ChangeBlockStatus(true)"
				>
					<i class="far fa-lock d-none d-md-inline-block mr-2"></i>
					bloquear
				</button>

				<button
					class="col-5 col-md-auto btn btn-light-warning btn-sm px-3 mr-md-1"
					@click="ChangeBlockStatus(false)"
				>
					<i class="far fa-unlock d-none d-md-inline-block mr-2"></i>
					desbloquear
				</button>

				<button 
					class="col-5 col-md-auto btn btn-light-danger btn-sm px-3 mr-md-1"
					v-if="selected_vision == 'day'"
					:disabled="doesnt_have_selecteds || !have_selected_schedulings"
					@click="CancelSelectedSchedulings()"
				>
					<i class="far fa-times d-none d-md-inline-block mr-2"></i>
					cancelar
				</button>

				<button 
					class="col-5 col-md-auto btn btn-light-info btn-sm px-3 mr-md-1"
					v-if="selected_vision == 'day'"
					:disabled="doesnt_have_selecteds || !have_selected_schedulings || !all_journeys_is_same"
					@click="Rescheduling()"
				>
					<i class="far fa-calendar-day d-none d-md-inline-block mr-2"></i>
					reagendar
				</button>
			</div>

			<template v-if="selected_vision == 'day'">
				<div class="col mt-3 col-md-auto mt-md-0 d-flex justify-content-between">
					<button 
						class="col-5 col-md-auto btn btn-light-success btn-sm mr-md-1"
						@click="$emit('get-fits')"
						:disabled="schedules.length === 0"	
					>
						<i class="far fa-file-import mr-2"></i>
						encaixes
					</button>
					<button 
						class="col-5 col-md-auto btn btn-light-primary btn-sm mr-md-1"
						@click="$emit('print-map')"
						:disabled="!have_schedulings"
					>
						imprimir
						<i class="far fa-print ml-3"></i>
					</button>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	const moment 		= require('moment')
	moment.locale('pt')

	const _ 			= require('lodash')

	import Swal from 'sweetalert2'
	import { mapGetters, mapAction, mapActions } from 'vuex'
	import Helpers from '../../../helpers'
	const helpers 		= new Helpers()

	export default {

		name: 'Calendar',
		props: 	{
			default_vision: 	{
				type: 				String,
				default: 			'month'
			},
			events: 		{
				type: 				Object,
				default: 			null
			},
			rows: 		{
				type: 				Array,
				default: 			() => []
			},
			schedules:	{
				type:				Array,
				default:			() => []
			},
			is_vacancy: {
				type:				Boolean,
				default:			false
			}
		},
		data () {
			return {
				search: 		'',
				weekdays: 		window.weekDays,
				initial: 		{
					month: 			moment().format('M'),
					today: 			moment().format('YYYY-MM-DD')
				},
				selected: 		{
					date: 			moment().format('YYYY-MM-DD'),
					day: 			moment().format('D'),
					month: 			moment().format('M'),
					year: 			moment().format('YYYY')
				},
				date: 			moment().format('YYYY-MM-DD'),
				current_month_days: 	[],
				previous_month_days: 	[],
				next_month_days: 		[],
				visions: 			[
					'year', 'month', 'day'
				],
				selected_vision: 		'month',
				selected_days: 			[],
				selected_months: 		[],
				selected_times: 		[],
				selected_block_type:	null,
				headers: 				[
					{ text: 'Hora', value: 'hora' },
					{ text: 'Paciente', value: 'nome_paciente' },
					{ text: 'ID/Numero', value: 'doc' },
					{ text: 'Procedimento', value: 'nome_procedimento' },
					{ text: 'Agenda', value: 'agenda' },
					{ text: 'Status', value: 'status_descricao' }
				]	
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id'
			]),
			date_indicator()
			{	
				let date 	= null

				switch(this.selected_vision)
				{
					case 'year':

						date 	= moment(new Date(this.selected.year, (this.selected.month - 1)))
							.format('MMMM')

						date 	= `${this.selected.year}`
					break
					case 'month':

						date 	= moment(new Date(this.selected.year, (this.selected.month - 1)))
							.format('MMMM')
							
						date 	= `${this.selected.year}, ` + date.charAt(0).toUpperCase() + date.substring(0, 3).slice(1)

					break
					case 'day':

						date 	= moment(new Date(this.selected.year, (this.selected.month - 1)))
							.format('MMMM')
							
						date 	= `${this.selected.year}, ${this.selected.day} ` + date.charAt(0).toUpperCase() + date.substring(0, 3).slice(1)

					break
				}

				return date
			},
			all_journeys_is_same() {
				if(this.selected_times.length > 0) {
					return this.selected_times.every(s => s === this.selected_times[0])
				}

				return false
			},
			doesnt_have_selecteds()
			{
				switch(this.selected_vision)
				{
					case 'day':

						return this.selected_times.length === 0

					case 'month':

						return this.selected_days.length === 0

					case 'year':

						return this.selected_months ? false : true

				}

				return false
			},
			have_selected_schedulings()
			{
				if(this.selected_vision == 'day')
				{
					let has 	= false

					const rows  	= this.parsed_rows.filter( r => this.selected_times.indexOf(r.id) > -1)

					rows.forEach( r => {
							if(r.scheduling_id)
							{
								has 	= true

								return;
							}
						})

					return has
				}

				return false
			},
			have_schedulings()
			{
				if(this.selected_vision == 'day')
				{
					const rows  	= this.parsed_rows.filter( r => r.scheduling_id)

					return rows.length > 0
				}

				return false
			},
			parsed_rows() {
				const rows = this.rows.map(el => {
					el.id = el.scheduling_id || el.block_id

					return el
				})

				return rows
			},
			months() {
				const events 	= this.events

				const months 	= window.year.map(y => {					
					if(events) {
						let index 	= y.value.toString().padStart(2, '0')

						y.index = index
						
						if(events[index] && events[index].heatmap) {
							y.heatmap = events[index].heatmap
							y.heatmap.allowed = y.heatmap.total - y.heatmap.blocked - y.heatmap.busy
						} else {
							y.heatmap = null
						}
					} else {
						y.heatmap = null
					}

					return y
				})

				return months
			},
			weeks()
			{	
				const days 		= [
	  					...this.previous_month_days, 
	  					...this.current_month_days, 
	  					...this.next_month_days
					]

				return _.chunk(days, 7)
			},
			vision_name()
			{
				let vision 	= ''

				switch(this.selected_vision)
				{
					case 'day':
						vision 	= 'dia'
					break
					case 'month':
						vision 	= 'mês'
					break
					case 'year':
						vision 	= 'ano'
					break
				}

				return vision		
			}
		},
		watch: {
			default_vision: {
				handler(val)
				{
					this.selected_vision 	= val
				},
				immediate: true
			},
			selected_vision(val)
			{
				this.selected_days 		= []
				this.selected_months 	= []
				this.selected_times 	= []

				this.$emit('vision-or-day-has-changed', {
					vision: val,
					day: this.selected.date
				})

				this.vision = val
			},
			'selected.date': {
				handler(val)
				{	
					this.selected_days 		= []
					this.selected_months 	= []
					this.selected_times 	= []

					this.date 				= val

					this.$emit('vision-or-day-has-changed', {
						vision: this.selected_vision,
						day: val
					})
				},
				immediate: true
			},
			'selected.month': {
				handler(val)
				{
					this.CreateCalendar()
				}
			},
			date(val)
			{
				this.selected.date 		= val

				let selectedDate 		= moment(this.selected.date)

				this.selected.day 		= selectedDate.clone().format('D')
				this.selected.month 	= selectedDate.clone().format('M')
				this.selected.year  	= selectedDate.clone().format('YYYY')
				
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			GetColorDay(item) {
				switch(this.selected_vision) {
					case 'month':
						if(moment(item.date).isSameOrAfter(moment(), 'day')) {
							if(this.GetHeatFree(item.date) > 0) {
								return 'text-success'
							} else if(this.GetHeatTotal(item.date)) {
								if(this.GetHeatBlocked(item.date)) {
									return 'text-warning'
								}
								
								return 'text-busy'
							}
						}

						return 'text-secondary opacity-50'
					case 'year': {
						const monthIndex = item.value.toString().padStart(2, '0')

						if(moment(`${this.selected.year}-${monthIndex}-01`).isSameOrAfter(moment(), 'month')) {
							if(this.GetHeatFree(monthIndex) > 0) {
								return 'text-success'
							} else if(this.GetHeatTotal(monthIndex)) {
								if(this.GetHeatBlocked(monthIndex)) {
									return 'text-warning'
								}
								
								return 'text-busy'
							}
						}
						
						return 'text-secondary opacity-50'
					}
				}
			},
			GetDisabledColor(item){
				switch(this.selected_vision)
				{
					case 'month':

						if(!moment(item.date).isSameOrAfter(moment(), 'day'))
						{
							return 'text-secondary opacity-50'
						}
					break
					case 'year':

						if(!moment(`${this.selected.year}-${item.value.toString().padStart(2, '0')}-01`).isSameOrAfter(moment(), 'month'))
						{
							return 'text-secondary opacity-50'
						}
					break
				}

				return ''
			},
			DateHasPassed(item) {
				let has_passed = false

				switch(this.selected_vision) {
					case 'day':
					case 'month':
						if(!moment(item.date).isSameOrAfter(moment(), 'day')) {
							has_passed = true
						}
					break
					case 'year':
						if(!moment(`${this.selected.year}-${item.value.toString().padStart(2, '0')}-01`).isSameOrAfter(moment(), 'month')) {
							has_passed = true
						}
					break
				}

				return has_passed
			},
			GoToMonth(month)
			{
				this.selected_vision 	= 'month'

				this.selected.date 		= `${this.selected.year}-${month.value.toString().padStart(2, '0')}-01`

				let selectedDate 		= moment(this.selected.date)

				this.selected.day 		= selectedDate.clone().format('D')
				this.selected.month 	= selectedDate.clone().format('M')
				this.selected.year  	= selectedDate.clone().format('YYYY')
			},
			GoToDay(day)
			{
				this.selected.date 		= day.date

				this.$nextTick(() => {

					this.selected_vision 	= 'day'

					let selectedDate 		= moment(this.selected.date)

					this.selected.day 		= selectedDate.clone().format('D')
					this.selected.month 	= selectedDate.clone().format('M')
					this.selected.year  	= selectedDate.clone().format('YYYY')
				})

				
			},
			GetDayBlocked(date)
			{
				if(this.events)
				{
					if(this.events[date] && this.events[date].day_blocked)
					{
						return this.events[date].day_blocked
					}
				}

				return false
				
			},
			GetHeat(date)
			{
				if(this.events)
				{
					if(this.events[date] && this.events[date].heatmap)
					{
						return true
					}
				}

				return false
				
			},
			GetHeatTotal(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						return this.events[item].heatmap.total;
					}
				}

				return 0;
			},
			GetHeatBusy(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						return this.events[item].heatmap.busy;
					}
				}

				return 0;
			},
			GetHeatFree(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						const total = this.is_vacancy ? this.events[item].heatmap.total_for_vacancys : this.events[item].heatmap.total
						const busy = this.events[item].heatmap.busy
						const blocked = this.is_vacancy ? this.events[item].heatmap.blocked_for_vacancys : this.events[item].heatmap.blocked

						return total - busy - blocked
					}
				}

				return 0;
			},
			GetHeatBlocked(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						return this.is_vacancy ? this.events[item].heatmap.blocked_for_vacancys : this.events[item].heatmap.blocked;
					}
				}

				return 0;
			},
			GetHeatFullBusy(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						let busy = this.events[item].heatmap.busy
						let blocked = this.is_vacancy ? this.events[item].heatmap.blocked_for_vacancys : this.events[item].heatmap.blocked

						const total = this.is_vacancy ? this.events[item].heatmap.total_for_vacancys : this.events[item].heatmap.total

						if(!total) return `[0%]`;

						busy = (busy * 100) / total;
						blocked = (blocked * 100) / total;

						let result = Math.round(busy) + Math.round(blocked);

						if(result > 100) result = 100
						if(result < 0) result = 0

						return `[${result}%]`;
					}
				}

				return '[0%]';
			},
			GetAttendances(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						if(this.events[item].heatmap.attendances) {
							return this.events[item].heatmap.attendances;
						}
					}
				}

				return 0;
			},
			GetHeatVacancyBusy(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						return (this.events[item].heatmap.total / this.GetAttendances(item)) * (this.events[item].heatmap.busy / 5);
					}
				}

				return 0;
			},
			GetProceedingsCount(item) {
				if(this.events) {
					if(this.events[item] && this.events[item].heatmap) {
						return this.events[item].heatmap.proceedings;
					}
				}

				return 0;
			},
			ParseToTime(amount) {
				return window.helpers.MinutesToTime(!isNaN(amount) ? amount : 0)
			},
			GetPercentage(amount, total) {
				let calc = amount / total

				calc = Math.round((calc < 0 ? 0 : calc) * 100)

				return !isNaN(calc) ? `[${calc}%]` : '[0%]'
			},
			GetTotalBasedPercentage(amount, date) {
				if(this.events) {
					if(this.events[date] && this.events[date].heatmap) {
						const total = this.is_vacancy ? this.events[date].heatmap.total_for_vacancys : this.events[date].heatmap.total
		
						if(amount && total) {
							let percent = (amount / total) * 100

							percent = Math.round(percent)
		
							return `[${!isNaN(percent) ? percent : 0}%]`
						}
						
						return '[0%]'
					}
				}
			},
			GetCheckeds(e)
			{
				this.selected_times 	= e

				this.selected_block_type = null

				if(this.selected_times.length > 0)
				{
					const selected_blocks = this.parsed_rows.filter( b => this.selected_times.indexOf(b.id) > -1)
						.map( s => s )
	
					this.selected_block_type = selected_blocks[0].block_type
				}
			},
			CanBeSelected(item) 
			{
                const vm 		= this

                return vm.dataTableSelect.items.every((k) => (k.block_type == item.block_type && k.block_available == item.block_available))
            },
			GetRowColor(item)
			{
				let class_name 	= ''

				switch (item.block_type)
				{
					case 2:
						class_name 	= 'bg-secondary'
					break
					
					case 3:
						class_name 	= 'bg-dark'
					break
					
				}

				return class_name
 			},
			CancelEvent(item)
			{
				this.$emit('cancel-event', item)
			},
			GetNumberOfDaysInMonth(year, month)
			{
				return moment(new Date(year, (month - 1), 1)).daysInMonth()
			},
			CreateDaysForCurrentMonth(year, month)
			{
				return [...Array(this.GetNumberOfDaysInMonth(year, month))].map((day, index) => {
					return {
						date: 			moment(new Date(year, (month - 1), (index + 1))).format("YYYY-MM-DD"),
					    dayOfMonth: 	index + 1,
					    isCurrentMonth: true
					}
				})
			},
			GetWeekday(year, month, day) 
			{
			  	return moment(new Date(year, (month - 1), day)).day()
			},
			CreateDaysForPreviousMonth(year, month)
			{
				const [y, m, d] 				= this.current_month_days[0].date.split('-')

			  	const firstDayOfTheMonthWeekday = this.GetWeekday(y, m, d)

			  	const previousMonth 			= moment(new Date(year, (month - 1), 1)).subtract(1, "month")

				// Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
				const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
				    ? firstDayOfTheMonthWeekday
				    : 0

				const previousMonthLastMondayDayOfMonth = moment(new Date(y, (m - 1), d))
				    .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
				    .date()

				return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
				    return {
				      	date: moment(new Date(previousMonth.year(), (previousMonth.month()), (previousMonthLastMondayDayOfMonth + index))).format("YYYY-MM-DD"),
				      	dayOfMonth: previousMonthLastMondayDayOfMonth + index,
				      	isCurrentMonth: false
				    }
				})
			},
			CreateDaysForNextMonth(year, month) 
			{
				const lastDayOfTheMonthWeekday 	= this.GetWeekday(year, month, this.current_month_days.length)

				const nextMonth 				= moment(new Date(year, (month - 1), 1)).add(1, "month")

				const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
					? 6 - lastDayOfTheMonthWeekday
					: 6

				return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
					return {
			  			date: 			moment(new Date(nextMonth.year(), (nextMonth.month()), (index + 1))).format("YYYY-MM-DD"),
			  			dayOfMonth: 	index + 1,
			  			isCurrentMonth: false
					}
				})
			},
			CreateCalendar(y = null, m = null)
			{
				const year 	= this.selected.year
				const month = this.selected.month

				this.current_month_days 	= this.CreateDaysForCurrentMonth(year, month)

				this.previous_month_days 	= this.CreateDaysForPreviousMonth(year, month);

 				this.next_month_days 		= this.CreateDaysForNextMonth(year, month);
			},
			NextDate()
			{
				let selectedDate 	= moment(this.selected.date)

				switch(this.selected_vision)
				{
					case 'year':
						selectedDate.add(1, 'y')
					break
					case 'month':
						selectedDate.add(1, 'M')
					break
					case 'day':
						selectedDate.add(1, 'd')
					break
				}

				this.selected.day 	= selectedDate.clone().format('D')
				this.selected.month = selectedDate.clone().format('M')
				this.selected.year  = selectedDate.clone().format('YYYY')
				this.selected.date  = selectedDate.clone().format('YYYY-MM-DD')
			},
			PreviousDate()
			{
				let selectedDate 	= moment(this.selected.date)

				switch(this.selected_vision)
				{
					case 'year':
						selectedDate.subtract(1, 'y')
					break
					case 'month':
						selectedDate.subtract(1, 'M')
					break
					case 'day':
						selectedDate.subtract(1, 'd')
					break
				}

				this.selected.day 	= selectedDate.clone().format('D')
				this.selected.month = selectedDate.clone().format('M')
				this.selected.year  = selectedDate.clone().format('YYYY')
				this.selected.date  = selectedDate.clone().format('YYYY-MM-DD')
			},
			SetToday()
			{
				let selectedDate 	= moment()

				this.selected.day 	= selectedDate.clone().format('D')
				this.selected.month = selectedDate.clone().format('M')
				this.selected.year  = selectedDate.clone().format('YYYY')
				this.selected.date  = selectedDate.clone().format('YYYY-MM-DD')
			},
			async ChangeBlockStatus(to_block) {
				let blocks = []

				switch(this.selected_vision) {
					case 'year':
						blocks = this.selected_months
						break
					case 'month':
						blocks = this.selected_days
						break
					case 'day':
						blocks = this.selected_times
						break
				}

				if(blocks.length > 0) {
					this.StartLoading()

					if(this.selected_vision == 'day') {
						blocks = this.parsed_rows.filter(e => blocks.includes(e.id) && !e.scheduling_id)
								.map(e => {
									return {
										id: e.block_id
									}
								})
					}

					await window.api.call('post', '/change-block-status', {
						vision: 		this.selected_vision,
						blocks:			blocks,
						account_id:		this.requester_selected_id,
						year:			this.selected.year,
						months:			this.selected_months.length ? this.selected_months : null,
						schedules:		this.schedules ?? [],
						to_block
					})
						.then(({data}) => {
							if(data.response) {
								this.$emit('refresh', this.selected_vision)

								window.helpers.successMessageTop(
									`Items ${to_block ? '' : 'des'}bloqueados com sucesso.`,
									4000)

								this.selected_months = []
								this.selected_days = []
								this.selected_times = []
							} else {
								Swal.fire({
									icon: 'error',
									title: 'Houve um erro ao concluir, contrate o suporte',
									position: 'top',
									toast: true,
									timerProgressBar: true
								})
							}
						})
						.finally(() => {
							this.FinishLoading()
							this.selected_block_type = null
						})
				}
			},
			async CancelSelectedSchedulings(accept = false) {
                const vm = this

                if(vm.selected_times.length > 0)
				{
					let acp 	= accept
					if(!acp)
					{
						const { value: act } = await Swal.fire({
							title: 'Cancelamento',
							text: 'Para confirmar o cancelamentos deste(s) agendamento(s) marque o campo abaixo:',
							input: 'checkbox',
							inputValue: 0,
							inputPlaceholder: '',
							inputValidator: (result) => {
								return !result && 'Para confirmar o cancelamento é necessário marcar o campo'
							},
							confirmButtonText:
								'Confirmar <i class="fa fa-arrow-right"></i>',
							focusConfirm: false,
							width: 'auto',
						})

						acp 	= act
					}

					if (!acp) return

                    this.StartLoading()

					const schedulings = vm.parsed_rows
						.filter(row => vm.selected_times.includes(row.scheduling_id))
						.map(row => {
							return {
								id: row.scheduling_id
							}
						})

					return window.api.call('post','/cancel-schedulings', {
	                        schedulings,
	                        account_id: this.requester_selected_id
						})
						.then(({data}) => {
							if(data.response) {
								window.helpers.successMessageTop('Agendamento cancelado com sucesso.', 4000)
								
								vm.$emit('refresh', this.selected_vision)
							} else {
								Swal.fire({
									icon: 'error',
									title: 'Ops!',
									text: data.message ?? 'Houve um erro ao concluir, contrate o suporte',
								})
							}
						})
						.finally(() => {
							this.dialog 	= false

							this.FinishLoading()
						})
				}else{

					this.FinishLoading()

					window.helpers.errorMessageTop('Selecione algum agendamento para continuar.', 4000)
				}
			},
			Rescheduling(){
				const vm = this

				if(vm.selected_vision === 'day') {

					const schedulings = vm.parsed_rows.filter(e => vm.selected_times.includes(e.id))

					const parse_schedulings = _.uniq(schedulings.map(e => e.scheduling_id))

					vm.$router.push({ name: 'ReschedulingWizard',
						query: {
							schedulings: JSON.stringify(parse_schedulings)
						},
						params: { 
							source: 'SchedulingMap' 
						}
					})
				}
			},
		},
		beforeMount()
		{
			this.CreateCalendar()
		}
	}
</script>

<style lang="css" scoped>
	.overflow-calendar-table {
		overflow: hidden auto;
		max-height: calc(100vh - 100px);
	}

	.header-day {
		min-width: 164.844px;
	}

	.data-container-month {
		min-width: 309.444px;
		min-height: 184.358px;
	}

	.data-container-day {
		min-width: 164.844px;
		min-height: 158.333px;
	}

	.free-label {
		position: absolute;
		left: 3.75rem;
	}

	.free-label-month {
		position: absolute;
		left: 7.45rem;
	}

	@media (max-width: 767px) {
		.gap-1 {
			gap: .75rem;
		}

		.gap-2 {
			gap: 1rem;
		}

		.gap-3 {
			gap: 1.25rem;
		}
	}
</style>
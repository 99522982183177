<template>
	<div class="container">
		<div class="row justify-content-between align-items-end">
			<div class="col-12 text-md-center">
				<div class="d-block mx-auto mb-4 max-500 pr-6 pr-md-0">
					<span>informações</span>
					<h4 class="font-24 font-md-32 m-0">Informações pessoais</h4>
				</div>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12">
				<div class="scroll-x scroll-transparent d-flex mx--5 mx-md-0">
					<ul id="nav-content-level-1" class="nav nav-pills nav-theme w-md-100 justify-content-center mb-4 mb-md-4 px-2">
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4" :class="{ 'active': tab === 'data' }" data-toggle="tab" href="#data" @click="tab = 'data'">Dados</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#password" @click="tab = 'password'">Mudar senha</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4"
								:class="{ 'active': tab === 'email' }"
								data-toggle="tab"
								href="#email"
								@click="tab = 'email'"
							>
								E-mail
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#theme" @click="tab = 'theme'">Tema</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4" data-toggle="tab" href="#stamps" @click="tab = 'stamps'">Carimbo</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4"
								:class="{ 'active': tab === 'accesses' }"
								data-toggle="tab"
								href="#accesses"
								@click="tab = 'accesses'"
							>
								Acessos
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4"
								:class="{ 'active': tab === 'subscriptions' }"
								data-toggle="tab"
								href="#subscriptions"
								@click="ResetComponent('subscriptions')"
							>
								Assinaturas
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link pb-md-4 px-md-4"
								:class="{ 'active': tab === 'payments' }"
								data-toggle="tab"
								href="#payments"
								@click="ResetComponent('payments')"
							>
								Métodos de Pagamento
							</a>
						</li>
					</ul>
				</div>

				<div id="tab-content-level-1" class="tab-content d-flex justify-content-center mb-4">
					<div v-if="tab == 'data'" id="data" class="tab-pane col-10" :class="{ 'active': tab === 'data' }">
						<PersonalInfo/>
					</div>

					<div v-if="tab == 'password'" id="password" class="tab-pane col-8">
						<Password/>
					</div>

					<div v-if="tab == 'email'" id="email" class="tab-pane col-10" :class="{ 'active': tab === 'email' }">
						<Email/>
					</div>
					<div v-if="tab == 'theme'" id="theme" class="tab-pane col-10">
						<Theme />
					</div>
					<div v-if="tab == 'stamps'" id="stamps" class="tab-pane col-12">
						<Stamps />
					</div>
					<div v-if="tab == 'accesses'" id="accesses" class="tab-pane col-12" :class="{ 'active': tab === 'accesses' }">
						<Accesses />
					</div>
					<div v-if="tab == 'subscriptions'" id="subscriptions" class="tab-pane col-12 px-0" :class="{ 'active': tab === 'subscriptions' }">
						<Subscriptions 
							v-if="!is_free_plan && has_verified_email"
							ref="subscriptions"
							:content_loaded="content_subscription_loaded"
							:client_secret="client_secret"
							:price="price"
							:cards="cards"
							:subscriptions="subscriptions"
							@redirect_after="RedirectAfter"
							@refresh_subscriptions="GetSubscriptions"
							@refresh_cards="RefreshCards"
							@reset_component="ResetComponent('subscriptions')"
						/>
						<div v-else class="col-12 px-0 text-center">
							Sua conta está isenta de assinaturas.
						</div>
					</div>
					<div v-if="tab == 'payments'" id="payments" class="tab-pane col-12 px-0" :class="{ 'active': tab === 'payments' }" >
						<Payments 
							v-if="!is_free_plan && has_verified_email"
							ref="payments"
							:content_loaded="content_payment_loaded"
							:client_secret="client_secret"
							:cards="cards"
							:subscriptions="subscriptions"
							:redirect_after="redirect_after"
							:open_modal="props_tab === 'payments'"
							@refresh_cards="RefreshCards"
							@refresh_subscriptions="GetSubscriptions"
							@reset_component="ResetComponent('payments')"
						/>
						<div v-else class="col-12 px-0 text-center">
							Sua conta está isenta de fornecer informações de pagamento.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Email from './sections/Email'
	import Password from './sections/Password'
	import PersonalInfo from './sections/PersonalInfo'
	import Theme from './sections/Theme'
	import Stamps from './sections/Stamps'
	import Accesses from './sections/Accesses'
	import Payments from './sections/Payments'
	import Subscriptions from './sections/Subscriptions'
	import Swal from 'sweetalert2'
	import _ from 'lodash'
	import { mapActions, mapGetters } from 'vuex'
	import moment from 'moment'

	export default {
		name: 'Index',
		components: 	{
			Email,
			Password,
			PersonalInfo,
			Theme,
			Stamps,
			Accesses,
			Payments,
			Subscriptions,
		},
		props: {
			props_tab:		{
				Type: 		String,
				default:	'accesses'
			}
		},
		data () {
			return {
				client_secret:					'',
				cards:							[],
				subscriptions:					[],
				price:							null,
				visible_tab:					this.tab,
				content_subscription_loaded: 	false,
				content_payment_loaded: 		false,
				tab:							'',
				redirect_after:					false
			}
		},
		computed: {
			...mapGetters('auth', [
				'account'
			]),
			professional_subscription() {
				return this.subscriptions.find(s => s.plan.type === 'licensed') || null
			},
			is_free_plan() {
				const is_free = !!this.subscriptions.find(s => s.plan.lookup_key === 'free')

				if(is_free) {
					const data_nav_link = window.jQuery('a[href="informacoes"]').eq(0)

					this.ResetActiveTabs(data_nav_link)

					this.tab = 'data'
				}

				return is_free;
			},
			has_verified_email() {
				return !!this.account.email_verified_at
			},
		},
		watch: {
			props_tab: {
				handler(val) {
					this.tab = val
				},
				immediate: true
			},
		},
		methods: {
			...mapActions('auth', [
				'GetAuthAccount'
			]),
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			RedirectAfter(val) {
				this.redirect_after = val
			},
			UpdateCards(val) {
				this.cards = val.cards
			},
			async RefreshCards() {
				await this.GetCards()
			},
			async GetSubscriptions() {
				const vm = this

				vm.content_subscription_loaded = false

				return await window.api.call('get', '/gateway/get-subscriptions')
					.then(async ({data}) => {
						if(data.response) {
							vm.subscriptions 					= [ ...data.subscriptions ]
						} else {
							vm.subscriptions 					= []

							await vm.GetAuthAccount();
						}
					})
					.finally(() => {
						vm.content_subscription_loaded = true
					})
			},
			async GetCards() {
				const vm = this;

				vm.content_payment_loaded = false;

				vm.StartLoading();

				window.jQuery('.InputElement').each(function (el) {
					window.jQuery(el).value = null;
				})

				return await window.api.call('get', '/gateway/get-cards')
					.then(({data}) => {
						if(data.response) {
							vm.client_secret 	= data.client_secret

							if(data.cards.length) {
								vm.cards = _.orderBy(data.cards.map((c, i) => {
									const exp_date = `${c.exp_month}/${c.exp_year}`
									const now = moment()
									
									return {
										...c,
										exp_date,
										is_or_expiring: moment(exp_date, 'MM/YYYY').isBefore(now)
									}
								}), 'id')
							} else {
								vm.cards = []
							}
							
							vm.price			= data.price

							return
						}
					})
					.finally(() => {
						vm.FinishLoading()
						
						vm.content_payment_loaded = true
					})
			},
			ResetComponent(type) {
				this.tab = type

				switch(type) {
					case 'payments': 
						this.content_payment_loaded = !this.content_payment_loaded

						this.$nextTick(() => {
							this.content_payment_loaded = !this.content_payment_loaded
						});
					break;
					case 'subscriptions':
						this.content_subscription_loaded = !this.content_subscription_loaded

						this.$nextTick(() => {
							this.content_subscription_loaded = !this.content_subscription_loaded
						});
					break;
				}
			},
			ResetActiveTabs(active_link) {
				const tab_pane_id = active_link.attr('href')
				
				window.jQuery(`#nav-content-level-1 .nav-item > a.nav-link`).not(active_link).each(function () {
					window.jQuery(this).removeClass('active');
				})

				window.jQuery(`#tab-content-level-1 > .tab-pane:not(${tab_pane_id})`).each(function () {
					window.jQuery(this).removeClass('active');
				})
			},
		},
		async mounted() {
			const vm = this

			window.jQuery('.nav-item').click(function () {
				const link = window.jQuery(this).find('a.nav-link').eq(0)

				vm.ResetActiveTabs(link)
			})

			if(vm.account.email && vm.account.email_verified_at) {
				await vm.GetCards()
	
				await vm.GetSubscriptions()
			} else {
				Swal.fire({
					title: 				'Ops!',
					text: 				'Autentique o seu e-mail para continuar!',
					icon: 				'info',
					confirmButtonText: 	'Ok',
					backdrop:           false,
					preConfirm: () => {
						window.jQuery('a[href="#email"]').tab('show')
					}
				})

				return false;
			}
		}

	}
</script>

<style lang="css" scoped>
@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1080px;
	}
}

#subscriptions {
	min-height: 50px;
	max-height: 700px;
	overflow: auto;
}
</style>
<template>
	<div class="container-large pb-4 pt-0">
		<div class="row justify-content-between align-items-end">
			<div class="col-12 mb-3 max-500 pr-6 pb-4 pb-md-0">
				<span>Relatórios</span>
				<h4 class="font-24 font-md-32 m-0">Gerar relatórios</h4>
			</div>
			<div class="col-12 max-400">
				<div class="row align-items-center pb-2">
					<div class="col-12 col-md mb-4 mb-md-0">
						<div class="form-group m-0 position-relative">
							<input
								type="search"
								class="form-control pl-5"
								placeholder="Buscar..."
								v-model="search"
							>
							<i class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"></i>
						</div>
					</div>

				</div>
			</div>
		</div>
		<hr class="my-4">
		<div class="row mb-4 mx--1">

			<div class="col-6 col-md-auto px-1">
				<div class="dropdown mb-2 mb-md-0">
					<button class="btn btn-light btn-block font-14 font-md-16" data-toggle="dropdown">
						<i class="far fa-chevron-down font-14 mr-3"></i> Data
					</button>
					<div class="dropdown-menu px-3 py-0">
						<form>
							<div class="form-group my-3">
								<label class="font-14 mb-1">do dia</label>
								<input
									type="date"
									class="form-control p-3"
									v-model="filters.date_init"
								>
							</div>
							<div class="form-group my-3">
								<label class="font-14 mb-1">até o dia</label>
								<input
									type="date"
									class="form-control p-3"
									v-model="filters.date_end"
								>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-6 col-md-auto px-1">
				<div id="columns-dropdown" class="dropdown mb-2 mb-md-0">
					<button class="btn btn-light btn-block font-14 font-md-16" data-toggle="dropdown">
						<i class="far fa-chevron-down font-14 mr-3"></i> Colunas
					</button>
					<div class="dropdown-menu px-3 py-0 mh-1000 overflow-auto" style="width: 275px">
						<form>
							<div
								v-for="(column, index) in columns"
								:key="index"
								class="form-check my-3"
							>
								<input
									type="checkbox"
									:value="columns.value"
									:id="column.text"
									v-model="column.selected"
                                    @change="( need_to_save_preferences = true )"
                                    hidden
								>
								<label class="col pr-0 form-check-label" :for="column.text">
									{{ column.text }}
								</label>
							</div>
						</form>
					</div>
				</div>
			</div>
			
			<div class="col-6 col-md-auto px-1">
				<div id="modules_and_modalities-dropdown" class="dropdown mb-2 mb-md-0 w-320">
					<button class="btn btn-light btn-block font-14 font-md-16" data-toggle="dropdown">
						<i class="far fa-chevron-down font-14 mr-3"></i> Módulos/Modalidades
					</button>
					<div class="dropdown-menu px-3 pb-0 pt-2 mh-1000 overflow-auto">
						<form>
							<div class="d-flex flex-wrap flex-column modules-container">
                                <div class="form-check form-check-inline">
									<input
										id="modules_and_modalities_select_all"
										class="form-check-input"
										type="checkbox"
										v-model="filters.modules_and_modalities.select_all"
                                        hidden
                                        @change="( need_to_save_preferences = true )"
									>
									<label 
										class="form-check-label w-100"
										for="modules_and_modalities_select_all"
									>
										SELECIONAR TUDO
									</label>
								</div>

                                <hr class="my-3">

                                <ul
                                    v-for="module in parsed_modules"
                                    :key="module.id"
                                >
                                    <li class="mb-2 list-unstyled">
                                        <div class="d-flex justify-content-between position-relative mb-2">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :id="'ChkModule_' + module.id"
                                                    :name="'ChkModule_' + module.id"
                                                    :value="!!module.id"
                                                    v-model="filters.modules_and_modalities[`module_${module.id}`].selected"
                                                    @change="ModuleChangeHandler($event, module.id)"
                                                    hidden
                                                >
                                                <label class="form-check-label" :for="'ChkModule_' + module.id">
                                                    {{ module.name }}
                                                </label>
                                            </div>
                                            <div class="chevron-expand-container"
                                                @click="(e) => { 
                                                    filters.modules_and_modalities[`module_${module.id}`].expanded = !filters.modules_and_modalities[`module_${module.id}`].expanded;
                                                    need_to_save_preferences = true;
                                                }">
                                                <i class="chevron-expand fa fal"
                                                    :class="{
                                                        'fa-chevron-right': !filters.modules_and_modalities[`module_${module.id}`].expanded,
                                                        'fa-chevron-down': filters.modules_and_modalities[`module_${module.id}`].expanded
                                                    }">
                                                </i>
                                            </div>
                                        </div>

                                        <div class="mb-3 collapse"
                                            :id="'collapse_' + module.id"
                                            :class="{
                                                'show': filters.modules_and_modalities[`module_${module.id}`].expanded
                                            }"
                                        >
                                            <ul v-for="modality in module.modalities"
                                                :key='modality.id'
                                            >
                                                <li class="list-unstyled ml-4 mb-2">
                                                    <div class="form-check form-check-inline">
                                                        <input
                                                            class="form-check-input"
                                                            type="checkbox"
                                                            :id="'ChkModality_' + modality.id"
                                                            :name="'ChkModality_' + modality.id"
                                                            :value="modality.id"
                                                            v-model="filters.modules_and_modalities[`module_${module.id}`].modalities"
                                                            @change="ModalityChangeHandler($event, modality.id)"
                                                            hidden
                                                        >
                                                        <label class="col pr-0 form-check-label" :for="'ChkModality_' + modality.id">
                                                            {{ modality.name }}
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-6 col-md-auto px-1">
                <button class="btn btn-primary btn-block font-14 font-md-16"
                    @click="Search">
                    <i class="far fa-search font-14 mr-3"></i> Buscar
                </button>
			</div>
		</div>

		<DataTables
			v-model="schedulings"
            max_height="500px"
            :trButton="true"
			:headers="dynamicHeaders"
			:search="search"
            :prop_sortedBy="sortedBy"
            :fixedHeader="true"
            :fetchSort="true"
            :returnFetchSort="false"
            @fetch="FetchSort"
            @by-click-on-tr="OpenRecordModal"
		>
			<template v-slot:body="{ item }">
                <td
               		v-for="(header, index) in dynamicHeaders"
               		:key="index"
               	>
                    <template v-if="header.value == 'scheduling_status'">
                        {{ GetStatus(item[header.value]) }}
                    </template>
                    <template v-else-if="header.value.substring(header.value.length - 10) == '_timestamp'">
                        {{ item[header.value] }}
                    </template>
                    <template v-else-if="header.cash_format">
                        R$ {{ item[header.value] && item[header.value].indexOf(',') > -1 ? item[header.value] : ParseMoney(item[header.value]) }}
                    </template>
                    <template v-else>
                        {{ item[header.value] }}
                    </template>
               </td>
			</template>
		</DataTables>

		<div class="row mb-4 mx--1 justify-content-between">
			<div class="col col-md-auto px-1 my-4 my-md-0">
				<div class="d-flex justify-content-between align-items-center">
					<button
						class="btn btn-block btn-light px-5 px-md-4 mr-md-2"
						@click="ExportData()"
						:disabled="schedulings.length === 0"
					>
						XLS
					</button>
				</div>
			</div>
			<div class="col-12 col-md-auto px-1">
				<button
					class="btn btn-block btn-primary px-5"
					:disabled="schedulings.length === 0"
                    @click="PrintReport"
				>
					imprimir
					<i class="far fa-print ml-3"></i>
				</button>
			</div>
		</div>

        <RecordModal
            :record="selected_record"
            @update-item="UpdateReportItem"
        />
	</div>
</template>

<script>
	import Swal from "sweetalert2"
	import { mapGetters, mapActions } from 'vuex'
    import RecordModal from './RecordModal'

	const helpers 	= window.helpers
	const _ 		= window._

	export default {

		name: 'Index',
        components: {
            RecordModal
        },
		data () {
            return {
                report_preferences:     {
                    modules: [],
                    modalities: [],
                    columns: []
                },
                need_to_save_preferences: false,
                print_dialog:       false,
                menu_date_init:     false,
                menu_date_end:      false,
                pagination: {
                    sortBy: 'date_init_timestamp', // The field that you're sorting by
                    descending: true
                },
                search:             '',
                filters:            {
                    mode:               1, //1 - ATENDIMENTO, 2 - FATURAMENTO, 3 - AMBOS
                    date_init:          new Date().toLocaleDateString().split('/').reverse().join('-'),
                    date_end:           new Date().toLocaleDateString().split('/').reverse().join('-'),
                    status:             [],
                    modules_and_modalities:            {
                        "module_1": { id: 1, selected: false, expanded: false, modalities: [] },
                        "module_2": { id: 2, selected: false, expanded: false, modalities: [] },
                        "module_3": { id: 3, selected: false, expanded: false, modalities: [] },
                        "module_4": { id: 4, selected: false, expanded: false, modalities: [] },
                        "module_5": { id: 5, selected: false, expanded: false, modalities: [] },
                        "module_6": { id: 6, selected: false, expanded: false, modalities: [] },
                        "module_7": { id: 7, selected: false, expanded: false, modalities: [] },
                        "module_8": { id: 8, selected: false, expanded: false, modalities: [] },
                        "module_9": { id: 9, selected: false, expanded: false, modalities: [] },
                        "module_10": { id: 10, selected: false, expanded: false, modalities: [] },
                        "select_all": false,
                    },
                    modalities:         [],
                },
                columns:   [
                    { text: 'Data Inicial', value: "date_init", selected: true, cash_format: false },
                    { text: 'Hora Inicial', value: "time_init", selected: true, cash_format: false },
                    { text: 'Data Final', value: "date_end", selected: true, cash_format: false },
                    { text: 'Hora Final', value: "time_end", selected: true, cash_format: false },
                    { text: 'Paciente', value: "patient_name", selected: true, cash_format: false },
                    { text: 'CPF', value: "patient_cpf", selected: true, cash_format: false },
                    { text: 'RG', value: "patient_rg", selected: true, cash_format: false },
                    { text: 'SUS', value: "patient_sus", selected: true, cash_format: false },
                    { text: 'RH', value: "patient_rh", selected: true, cash_format: false },
                    { text: 'Módulo', value: "module_name", selected: true, cash_format: false },
                    { text: 'Modalidade', value: "modality_name", selected: true, cash_format: false },
                    { text: 'Agenda', value: "schedule_name", selected: false, cash_format: false },
                    { text: 'Convênio', value: "partnership_name", selected: true, cash_format: false },
                    { text: 'Autorização', value: "authorization", selected: true, cash_format: false },
                    { text: 'Solicitante', value: "indicator", selected: true, cash_format: false },
					{ text: 'Procedimento AGD', value: "scheduled_proceeding", selected: false, cash_format: false },
					{ text: 'Cód. Proc. AGD', value: "scheduled_proceeding_code", selected: false, cash_format: false },
                    { text: 'Procedimento ATD', value: "released_proceeding", selected: true, cash_format: false },
                    { text: 'Cód. Proc. ATD', value: "released_internal_code", selected: true, cash_format: false },
                    { text: 'Autor', value: "user_name", selected: true, cash_format: false },
                    { text: 'Ident. Autor', value: "document_user", selected: true, cash_format: false },
                    { text: 'Valor', value: "proceeding_value", selected: true, cash_format: true },
					{ text: 'Alíquota', value: "aliquot_value", selected: true, cash_format: true },
					{ text: 'Honorário', value: "honorary_value", selected: true, cash_format: true },
					{ text: 'Fat. Líquido', value: "net_revenue", selected: true, cash_format: true },
					{ text: 'Pg. Dinheiro', value: "payment_money", selected: false, cash_format: true },
					{ text: 'Pg. Pix', value: "payment_pix", selected: false, cash_format: true },
					{ text: 'Pg. Débito', value: "payment_debit", selected: false, cash_format: true },
					{ text: 'Pg. Crédito', value: "payment_credit", selected: false, cash_format: true },
					{ text: 'Desconto', value: "discount", selected: false, cash_format: true },
                    // { text: 'Data Inicial (TimeStamp)', value: "date_init_timestamp", selected: false },
                    // { text: 'Hora Inicial (TimeStamp)', value: "time_init_timestamp", selected: false },
                    // { text: 'Data Final (TimeStamp)', value: "date_end_timestamp", selected: false },
                    // { text: 'Hora Final (TimeStamp)', value: "time_end_timestamp", selected: false },
                ],
                sortedBy:           {
                    column:         'date_init',
                    direction:      "ASC",
                    icon:           "fa-chevron-up"
                },
                schedulings:        [],
                selected_record:    null
            }
        },
        watch: {
            filters: {
                handler(val)
                {
                    // this.GetSchedulingsToReport()
                },
                deep: true
            },
            requester_selected_id(value)
            {
                this.GetSchedulingsToReport();
            },
            'filters.modules_and_modalities.select_all': {
                handler(val) {
                    const modules_and_modalities = JSON.parse(JSON.stringify(this.filters.modules_and_modalities))

                    for(const [index, module] of Object.entries(modules_and_modalities)) {
                        if(index != 'select_all') {
                            this.filters.modules_and_modalities[index].selected = val

                            if(val) {
                                this.filters.modules_and_modalities[index].modalities = this.modalities.filter(m => m.module_id == module.id)
                                    .map(m => m.id)
                            } else {
                                this.filters.modules_and_modalities[index].modalities = []
                            }
                        }
                    }
                },
            },
        },
        computed: {
        	...mapGetters('auth', [
				'requester_selected_id', 'requesters', 'roles'
			]),
            ...mapGetters('system', [
                'modules', 'modalities'
            ]),
            workgroup_schema() {
                return this.requesters.find(requester => requester.account_requester_id == this.requester_selected_id).workgroup_schema || ''
            },
            dynamicHeaders() {
                if(this.columns.length) {
                    return this.columns
                        .map(c => {
                            return { ...c, sortable: true }
                        })
                        .filter(c => c.selected)
                }

                return []
            },
            computedDateInitFormatted()
            {
                return helpers.FormatDate(this.filters.date_init);
            },
            computedDateEndFormatted()
            {
                return helpers.FormatDate(this.filters.date_end);
            },
            schedulingStatus()
            {
                return [
                    /*{ name: "AGENDADO", value: 1 },
                    { name: "CONFIRMADO", value: 2 },
                    { name: "ESPERA", value: 3 },*/
                    { name: "CHAMADO", value: 4 },
                    { name: "ATENDIMENTO", value: 5 },
                    { name: "PENDENTE", value: 6 },
                    { name: "ATENDIDO", value: 7 },
                    { name: "FINALIZADO", value: 8 },
                    //{ name: 'ENTREGUE', value: 9 },
                    //{ name: 'ENTREGUE[2]', value: 10 },
                    /*{ name: "FALTA", value: 11 },
                    { name: "CANCELADO[DOC]", value: 12 },
                    { name: "CANCELADO[AGD]", value: 13 },
                    { name: "CANCELADO[ATD]", value: 14 },
                    { name: "CANCELADO[ECX]", value: 17 },*/
                    // { name: 'RETORNO', value: 15 },
                    { name: "ENCAIXE", value: 16 }
                ]
            },
            parsed_modules() {
                return this.modules.map(module => {
                        return {
                            ...module,
                            modalities: this.modalities.filter(modality => modality.module_id == module.id)
                        }
                    })
            }
        },
        methods: {
            ...mapActions('system', [
            	'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
            ]),
            ModuleChangeHandler(event, module_id) {
                this.need_to_save_preferences = true

                if(!event.target.checked) {
                    this.filters.modules_and_modalities[`module_${module_id}`].modalities = []
                    // if(this.filters.modules_and_modalities[`module_${module_id}`].modalities.length) {
                    //     event.target.checked = true
                    // }
                } else {
                    if(!this.filters.modules_and_modalities[`module_${module_id}`].modalities.length) {
                        this.filters.modules_and_modalities[`module_${module_id}`].modalities = this.modalities.filter(m => m.module_id == module_id)
                            .map(m => m.id)
                    }
                }
            },
            ModalityChangeHandler(event, modality_id) {
                this.need_to_save_preferences = true

                const modality = this.modalities.find(m => m.id == modality_id)

                // if(event.target.checked) {
                    if(!this.filters.modules_and_modalities[`module_${modality.module_id}`].selected) {
                        this.filters.modules_and_modalities[`module_${modality.module_id}`].selected = true
                    }
                // }
            },
            async GetSchedulingsToReport() {
            	if(!this.requester_selected_id) {
            		return;
            	}

                this.StartLoading()

                this.schedulings        = []

                const modules_and_modalities = JSON.parse(JSON.stringify(this.filters.modules_and_modalities))

                this.filters.modalities = []
                
                for(const [index, module] of Object.entries(modules_and_modalities)) {
                    if(index != 'select_all') {
                        if(module.selected && !module.modalities.length) {
                            module.modalities = this.modalities.filter(m => m.module_id == module.id)
                                .map(m => m.id)
                        }

                        module.modalities.forEach(modality => {
                            this.filters.modalities.push(modality)
                        })
                    }
                }

                return await window.api.call('post', '/get-schedulings-to-report', {
                        account_id: this.requester_selected_id,
                        ...this.filters,
                        modules_and_modalities: undefined,
                        sort_by: { ...this.sortedBy }
                    })
                    .then(({data}) => {
                        if(data.response) {
                            this.schedulings = data.items
                        } else {
                            Swal.fire({
                                title:      'Opss...',
                                text:       'Houve um erro ao obter, contate o suporte.',
                                icon:       'error'
                            })
                        }
                    })
                    .finally(() => {
                        this.FinishLoading()
                    })
            },
            async FetchSort(params) {
                const vm = this

                this.sortedBy = JSON.parse(JSON.stringify({ ...params }))

                this.SaveReportPreferences()
            },
            PrintReport() {
                let status = _.map(this.filters.status, ['id'])

                let headers = this.dynamicHeaders.map(d => {
                    return {
                        value:  d.value,
                        text:   d.text
                    }
                })

                const url = `/relatorio/imprimir?status=${JSON.stringify(status)}&headers=${JSON.stringify(headers)}&date_init=${this.filters.date_init}&date_end=${this.filters.date_end}&modalities=${JSON.stringify(this.filters.modalities)}&mode=${this.filters.mode}&sort=${JSON.stringify(this.sortedBy)}&search=${this.search}`

                const print = window.open(url)

                print.mainWindow = window
            },
            GetStatus(status)
            {
                const item    = this.schedulingStatus.find( sch => sch.value === status)

                return item ? item.name : status
            },
            FilterAll(items, queryText) {
                const searchText = queryText

                const arrSearch = this.dynamicHeaders.map(dyn => dyn.value);

                let resultSearch = items

                if (searchText) {
                    resultSearch = resultSearch.filter((res) => {
                        let allowed = false

                        arrSearch.map((param) => {
                            const paramValue = _.get(res, param);

                            const tmp = paramValue ? window.helpers.SanitizedDocument(window.helpers.RemoveAcento(paramValue.toString().toLowerCase())).includes(window.helpers.SanitizedDocument(window.helpers.RemoveAcento(searchText.toLowerCase()))) : false;

                            if(tmp) allowed = true
                        })

                        return allowed
                    })
                }
                
                return resultSearch
            },
            ExportData()
            {
                this.StartLoading()

                const obj       = {
                    account_id:         this.requester_selected_id,
                    ...this.filters,
                    headers: this.columns.filter(c => c.selected).map(c => c.value),
                    sort_by: { ...this.sortedBy },
                    empty_label: '',
                }

                const filename	= `${this.filters.mode === 2 ? 'faturamento' : 'atendimento'}_${this.filters.date_init}_${this.filters.date_end}`

                window.axios({
                        url: '/download-excel-report',
                        method: 'POST',
                        data:   obj,
                        responseType: 'blob', // important
                    }).then((response) => {

                        const url               = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' }));
                        const link              = document.createElement('a');
                        link.href               = url;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .finally(() => {
                        this.FinishLoading()
                    });
            },
            ParseMoney(value) {
                return `${parseFloat(value).toFixed(2)}`.replace('.', ',')
            },
            async Search() {
                await this.GetSchedulingsToReport()

                if(this.schedulings.length) {
                    const filteredItems = this.FilterAll(this.schedulings, this.search)
                    this.schedulings = filteredItems
                }
            },
            async SaveReportPreferences() {
                const vm = this

                return await window.api.call('post', '/save-report-preferences', {
                    columns: vm.columns,
                    modules_and_modalities: vm.filters.modules_and_modalities,
                    sortedBy: vm.sortedBy,
                    workgroup_schema: vm.workgroup_schema
                })
                    .then(({data}) => {
                        if(data.response) {
                            vm.need_to_save_preferences = false;

                            return;
                        }

                        Swal.fire({
                            title: 'Erro ao salvar as configurações de filtro!',
                            toast: true,
                            position: 'top',
                            icon: 'error'
                        })
                    })
            },
            async GetReportPreferences() {
                const vm = this

                vm.StartLoading()

                return await window.api.call('post', '/get-report-preferences', {
                        workgroup_schema: vm.workgroup_schema
                    })
                    .then(({data}) => {
                        if(data.response) {
                            vm.report_preferences = data.report_preferences

                            vm.columns = !vm.report_preferences.columns ? vm.columns : vm.report_preferences.columns

                            vm.filters.modules_and_modalities = vm.report_preferences.modules_and_modalities ? JSON.parse(vm.report_preferences.modules_and_modalities) : vm.filters.modules_and_modalities

                            vm.sortedBy = vm.report_preferences.sortedBy
                                ?  vm.report_preferences.sortedBy
                                :  {
                                    column:         vm.columns[0].value,
                                    direction:      "ASC",
                                    icon:           "fa-chevron-up"
                                }
                        }
                    })
                    .finally(() => {
                        vm.FinishLoading()
                    })
            },
            HasRecordEditPermission() {
                const EDIT_REPORT_ROLE = 33

                const has_permission = this.roles.indexOf(EDIT_REPORT_ROLE) > -1

                return has_permission
            },
            OpenRecordModal(record) {
                if(!this.HasRecordEditPermission()) return;

                this.selected_record = record

                window.jQuery('#record-modal').modal('show')
            },
            UpdateReportItem(record) {
                // const index = this.schedulings.findIndex(scheduling => scheduling.code == record.code)

                // this.schedulings[index] = record

                this.GetSchedulingsToReport()
            }
        },
        async beforeMount()
        {
            await this.GetModulesAndModalities()
            await this.GetReportPreferences()
            await this.GetSchedulingsToReport()
        },
        mounted() {
            window.jQuery('#columns-dropdown').on('hidden.bs.dropdown', async () => {
                if(!this.need_to_save_preferences) return;

                await this.SaveReportPreferences()
            })

            window.jQuery('#modules_and_modalities-dropdown').on('hidden.bs.dropdown', async () => {
                if(!this.need_to_save_preferences) return;

                await this.SaveReportPreferences()
            })
        }
    }
</script>

<style lang="css" scoped>
    @media only screen and (min-width: 768px){
        .container-medium{
            max-width: 1170px;
        }

        .container-large{
            max-width: 1740px;
        }
    }

    .chevron-expand-container {
        position: absolute;
        right: 0;
        top: 0;
        width: 92%;
        height: 24.02px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .mh-1000 {
        max-height: 1000%;
    }

    .w-320 {
        width: 320px;
    }
</style>